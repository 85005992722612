import styled from 'styled-components';

export const Paper = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  height: auto;
`;
export const BoxItem = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  width: fit-content;

  p {
    font-weight: 600;
    font-size: 24px;
  }
`;
