/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { OperatorResponse } from '~/services/consult/user/detail/response';
import Endpoints from '~/services/endpoints';
import {
  GetOperatorsResponse,
  ReportCardReissuanceResponse,
  ReportInvoiceInactiveCards,
  ReportSalesPlatinumCardResponse,
  ReportUnlockedCardsResponse,
} from '~/services/report/requestReport/response';
import { CSV_HEADERS } from '../../constants';
import Models from '../../models';

const CardContext = createContext<ICardReport>({} as ICardReport);

export interface ICardReport {
  getReportUnlockedCards: Function;
  getReportInactiveCards: Function;
  getReportSalesPlatinumCard: Function;
  getReportCardReissuance: Function;
  getReportCardCSVDownload: Function;
  getOperador: Function;
  items: any;
  headerItems: any;
  header: any;
  totalPages: number;
  totalItems: number | null;
  relatorioData: any;
  setRelatorioData: React.SetStateAction<any>;
  loading: boolean;
  clearForm: () => React.SetStateAction<any>;
  REPORT_TYPES: Array<{ id: string; name: string; value: string }>;
  operators: any[];
}

interface IDownloadCSV {
  base64: string;
  filename: string;
}

const CardProvider: React.FC = ({ children }) => {
  const [items, setItems] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [header, setHeader] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState<null | number>(null);
  const [relatorioData, setRelatorioData] = useState([]);
  const [operators, setOperators] = useState([]);

  const RequestReportUnlockedCards = useApi<ReportUnlockedCardsResponse>(
    Endpoints.report.getReportUnlockedCards,
    ApiMethod.POST,
  );

  const RequestReportInactiveCards = useApi<ReportInvoiceInactiveCards>(
    Endpoints.report.getReportInactiveCards,
    ApiMethod.POST,
  );

  const RequestReportSalesPlatinumCard = useApi<ReportSalesPlatinumCardResponse>(
    Endpoints.events.postReportSalesPlatinum,
    ApiMethod.POST,
  );

  const RequestReportCardReissuance = useApi<ReportCardReissuanceResponse>(
    Endpoints.report.getReportCardReissuance,
    ApiMethod.GET,
    {},
    true,
  );

  const RequestReportCardReissuanceCSV = useApi<IDownloadCSV>(
    Endpoints.report.getReportCardReissuanceCSV,
    ApiMethod.GET,
    {},
    true,
  );

  const GetOperators = useApi<OperatorResponse[]>(
    Endpoints.report.getOperators,
    ApiMethod.GET,
  );

  const getOperators = useCallback(async () => {
    const { data, status, mensagem } = await GetOperators.callApi();

    return { data, status, mensagem };
  }, [GetOperators]);

  const getOperador = async () => {
    const response = await getOperators();

    if (response.status === 200) {
      const data = response.data as GetOperatorsResponse[];

      const getOperadores = data.map(({ nome, id }) => ({
        id,
        value: nome,
      }));

      setOperators(getOperadores);
    }
  };

  const clearForm = () => {
    setItems([]);
    setHeader([]);
  };

  const loading =
    RequestReportUnlockedCards.loading ||
    RequestReportInactiveCards.loading ||
    RequestReportSalesPlatinumCard.loading ||
    RequestReportCardReissuance.loading ||
    RequestReportCardReissuanceCSV.loading ||
    GetOperators.loading;

  const REPORT_TYPES = [
    {
      id: 'INATIVOS',
      name: 'Cartões Inativos',
      value: 'Cartões Inativos',
    },
    {
      id: 'DESBLOQUEADOS',
      name: 'Cartões Desbloqueados',
      value: 'Cartões Desbloqueados',
    },
    {
      id: 'SEGUNDA_VIA',
      name: 'Solicitação de 2º via',
      value: 'Solicitação de 2º via',
    },
  ];

  const getReportUnlockedCards = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = {
      ...payload,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportUnlockedCards.callApi(newPayload);

    setHeader(CSV_HEADERS.UNLOCKED_CARDS);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderUnlockedCards);
      setItems(Models.BodyUnlockedCards(data));
    }
  };

  const getReportCardReissuance = async (
    page = 1,
    limit = 10,
    payload,
    returnResponse = false,
  ) => {
    const newPayload = { ...payload, page, size: limit };

    const response = await RequestReportCardReissuance.callApi(newPayload);

    if (returnResponse) return response.data;

    const { data, totalPages, pageSize, totalItems } = response.data;

    if (response.status === 200) {
      setTotalPages(totalPages);
      setTotalItems(totalItems);
      setHeaderItems(Models.HeaderCardReissuance);
      setItems(Models.BodyCardReissuance(data));
    }
  };

  const getReportInactiveCards = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = { ...payload, limitePorPagina: limit, pagina: page };

    const response = await RequestReportInactiveCards.callApi(newPayload);

    setHeader(CSV_HEADERS.INACTIVE_CARDS);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderInactiveCards);
      setItems(Models.BodyInactiveCards(data));
    }
  };

  const getReportSalesPlatinumCard = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    const newPayload = { ...payload, qtdRegistros: limit, pagina: page };

    const response = await RequestReportSalesPlatinumCard.callApi(newPayload);

    setHeader(CSV_HEADERS.SALES_PLATINUM_CARD);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalPaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderUpgradePlatinumCard);
      setItems(Models.BodyUpgradePlatinumCard(data));
      setRelatorioData(Models.BodyUpgradePlatinumCard(data?.data));
    }
  };

  const getReportCardCSVDownload = async (payload) => {
    try {
      const response = await RequestReportCardReissuanceCSV.callApi(payload);

      if (response.status === 200) {
        const binary = atob(response?.data?.base64);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);
        const contentType = 'text/csv;charset=UTF-8';

        for (let i = 0; i < binary.length; i += 1) {
          view[i] = binary.charCodeAt(i);
        }

        const url = window.URL.createObjectURL(
          new Blob(['\ufeff', view], { type: contentType }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.data.filename);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CardContext.Provider
      value={{
        getReportUnlockedCards,
        getReportInactiveCards,
        getReportSalesPlatinumCard,
        getReportCardReissuance,
        getReportCardCSVDownload,
        items,
        headerItems,
        header,
        totalPages,
        totalItems,
        loading,
        REPORT_TYPES,
        relatorioData,
        setRelatorioData,
        clearForm,
        getOperador,
        operators,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};
export { CardProvider, CardContext };
