import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask } from '~/utils/masks';

export const HeaderCardReissuance = [
  {
    id: 1,
    value: 'Nome completo',
    maxChar: 20,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data e Hora da solicitação',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Motivo do bloqueio',
    maxChar: 15,
    textAlign: 'center  ',
  },
  {
    id: 5,
    value: 'Tema',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Tarifa',
    maxChar: 10,
  },
];

export const BodyCardReissuance = (data: any) => {
  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      {
        value: i.nome,
        maxChar: 20,
      },
      {
        value: cpfMask(i.cpfCliente).masked,
        maxChar: 14,
        textAlign: 'center',
      },
      {
        value: i.dataSolicitacao
          ? moment(i.dataSolicitacao).format('DD/MM/YYYY HH:mm')
          : '',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.motivoBloqueio,
        maxChar: 15,
        textAlign: 'center',
      },
      {
        value: i.tema,
        maxChar: 15,
        textAlign: 'center',
      },
      { value: i.tarifa, maxChar: 10, textAlign: 'center' },
    ];
    items.push(item);
  });

  return items;
};
