/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import * as St from './styles';
import { icons } from '~/assets';
import { currencyMask } from '~/utils/masks';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import { IAntecipationPurchase, ISelectParcels } from '../../model';

const SelectParcelsQuantity: React.FC<ISelectParcels> = ({
  data,
  qtdParcelasAPagar,
  setDataParcelas,
}) => {
  const [parcelsQuantity, setParcelsQuantity] = useState(
    data?.parcelasAAntecipar || 0,
  );
  const [loading, setLoading] = useState(false);
  const [dialogIsFail, setDialogIsFail] = useState(false);
  const [parcelsData, setParcelsData] = useState<IAntecipationPurchase>({
    valorAntecipacao: data?.valorAntecipacao || 0,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [requestCalculation, setRequestCalculation] = useState(false);

  const RequestCreateAntecipation = useApi(
    Endpoints.invoices.postAntecipation,
    ApiMethod.POST,
  );

  const getCalculationParcels = useApi(
    Endpoints.invoices.getAntecipationPurchaseCalculation
      .replace('PARAM_ID_COMPRA', data.idCompra)
      .replace('PARAM_QTD_PARCELAS', String(parcelsQuantity)),
    ApiMethod.GET,
  );

  const calculationParcels = async () => {
    setLoading(true);

    const response: any = await getCalculationParcels.callApi();

    if (response.status >= 200 && response.status < 300) {
      setParcelsData(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
      setDialogIsFail(true);
      setErrorMessage('Não foi possível calcular as parcelas!');
      setParcelsQuantity(0);
    }
  };

  useEffect(() => {
    if (requestCalculation) {
      calculationParcels();
    }
  }, [parcelsQuantity, requestCalculation]);

  const handleParcelsQuantity = (operation: string) => {
    if (operation === '+') {
      setParcelsQuantity((prev) => prev + 1);
      setRequestCalculation(true);
    }
    if (operation === '-') {
      if (parcelsQuantity === 0) return;
      if (parcelsQuantity === 1) {
        const payload = {
          parcelasAAntecipar: 0,
          valorAntecipacao: 0,
          tipoAntecipacao: 'COMPRA',
          idCompra: data.idCompra,
        } as IAntecipationPurchase;

        setDataParcelas(payload);
        return;
      }

      setParcelsQuantity((prev) => prev - 1);
      setRequestCalculation(true);
    }
  };

  function handleClick() {
    const payload = {
      parcelasAAntecipar: parcelsData.parcelasAAntecipar,
      valorAntecipacao: parcelsData.valorAntecipacao,
      tipoAntecipacao: 'COMPRA',
      idCompra: data.idCompra,
    } as IAntecipationPurchase;

    setDataParcelas(payload);
  }

  return (
    <>
      <Loading status={loading || RequestCreateAntecipation.loading} />
      <ContainerFlex width={50} center style={{ minHeight: '180px' }}>
        <St.Title>Antecipar quantas parcelas?</St.Title>
        <ContainerFlex row horizontal center style={{ gap: '20px' }}>
          <St.ActionsButton
            onClick={() => handleParcelsQuantity('-')}
            type="button"
            disabled={parcelsQuantity === 0}
          >
            <AiOutlineMinus />
          </St.ActionsButton>
          <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
            {`${String(parcelsQuantity).padStart(2, '0')} de ${String(
              qtdParcelasAPagar,
            ).padStart(2, '0')}`}
          </span>
          <St.ActionsButton
            onClick={() => handleParcelsQuantity('+')}
            type="button"
            disabled={parcelsQuantity >= qtdParcelasAPagar}
          >
            <AiOutlinePlus />
          </St.ActionsButton>
        </ContainerFlex>
        <St.Value>
          Valor: R$
          {` ${currencyMask(parcelsData?.valorAntecipacao)}`}
        </St.Value>
        <ContainerFlex center horizontal style={{ gap: '20px' }} row>
          <St.ButtonMain
            onClick={() => {
              handleClick();
            }}
            fitContent
            type="button"
            disabled={parcelsQuantity === 0}
          >
            Solicitar Antecipação
          </St.ButtonMain>
        </ContainerFlex>
        <Dialog
          icon={icons.warning}
          open={dialogIsFail}
          message={errorMessage}
          positiveLabel="Entendi"
          positiveAction={() => {
            setDialogIsFail(false);
          }}
        />
      </ContainerFlex>
    </>
  );
};

export default SelectParcelsQuantity;
