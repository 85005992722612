/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/newInput';
import Loading from '~/components/loading';
import Select, { IOption } from '~/components/select';
import TitleApp from '~/components/title';
import { useAuth } from '~/hooks/contexts/useAuth';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { ICompany } from '~/models/company';
import { IEmployee } from '~/models/employee';
import { UserTypes } from '~/models/user';
import endpoints from '~/services/endpoints';
import Constants from '~/utils/constants';
import { transformInGlobalDate, transformInNormalDate } from '~/utils/date';
import {
  cepMask,
  cpfMask,
  dateMask,
  IDefaultReturn,
  phoneMask,
} from '~/utils/masks';
import * as St from './styles';
import Collapse from '~/components/collapse';
import AutoCompleteSelect from '~/components/AutoCompleteSelect';
import PasswordModal from '~/components/passwordModal';

interface IForm {
  cpf: IDefaultReturn;
  name: string;
  phone: IDefaultReturn;
  dateBirth: IDefaultReturn;
  email: string;
  login?: string;
  cep: IDefaultReturn;
  address: string;
  number: string;
  complement: string;
  city: string;
  neighborhood: string;
  date?: IDefaultReturn;
  hour?: IDefaultReturn;
  password?: string;
  confPassword?: string;
  perfis?: string[];
  idEmpresa?: string;
  funcionarioId?: string;
  mensagem?: string;
  ativo: boolean;
  idFuncionario?: string;
  isClient?: boolean;
  isEmployee?: boolean;
  perfilFuncionario?: string;
  bloqueado?: boolean;
}
export interface IUser {
  ativo: boolean;
  celular: string;
  cpf: string;
  dataNascimento: string;
  email: string;
  endereco: Endereco;
  id: string;
  idEmpresa: string;
  nome: string;
  perfis: string[];
}

export interface Endereco {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  logradouro: string;
  numero: string;
  pais: string;
  uf: string;
}

const STATUS_TYPE = [
  {
    id: 'ADM',
    value: UserTypes.ADMIN,
  },
  {
    id: 'OPR',
    value: UserTypes.OPERADOR,
  },
  {
    id: 'SUP',
    value: UserTypes.SUPERVISOR,
  },
  {
    id: 'MDC',
    value: UserTypes.MESA_CREDITO,
  },
  {
    id: 'RET',
    value: UserTypes.RETAGUARDA,
  },
  {
    id: 'CXA',
    value: UserTypes.CAIXA,
  },
  {
    id: 'ASSESSORIA',
    value: UserTypes.ASSESSORIA,
  },
];

const RegisterOperator: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [openDialogEmail, setOpenDialogEmail] = useState(false);
  const [statusEmail, setStatusEmail] = useState(false);
  const [openDialogBlock, setOpenDialogBlock] = useState(false);
  const [dialogResetPassword, setDialogResetPassword] = useState(false);
  const [modalPasswordOpen, setModalPasswordOpen] = useState<{
    open: boolean;
    type: 'resetPassword' | '';
  }>({ open: false, type: '' });
  const [msgDialogEmail, setMsgDialogEmail] = useState('');
  const admin = JSON.parse(localStorage.getItem(Constants.USER_ROLE));
  const isAdmin = admin.includes('ADM') && true;
  const isEmployee = admin.includes('ADM' || 'SUP') && true;

  const { state = {} } = useLocation<IEmployee>();
  const history = useHistory();
  const { user } = useAuth();

  const [form, setForm] = useState<IForm>({
    cpf: cpfMask(state?.cpf),
    name: state.nome,
    phone: phoneMask(state?.celular),
    dateBirth: transformInNormalDate(state?.dataNascimento),
    email: state.email,
    cep: cepMask(state?.endereco?.cep),
    address: state.endereco?.logradouro,
    number: state.endereco?.numero,
    complement: state.endereco?.complemento,
    city: state.endereco?.cidade,
    neighborhood: state.endereco?.bairro,
    funcionarioId: state?.id,
    idEmpresa: state.idEmpresa,
    perfis: state.perfis || [],
    ativo: state.usuarioAtivo,
  });

  const userIsNotAdmin = !user?.perfis?.some(
    (a: string) =>
      a === Constants.ROLE_ADMIN || a === Constants.ROLE_SUPERVISOR,
  );

  const GetUserDetail = useApi<IUser>(
    endpoints.consult.getUserDetail.replace('PARAM_CPF', state.cpf),
    ApiMethod.GET,
  );

  const PutEnableDisableUser = useApi<any>(
    form?.ativo
      ? `${endpoints.account.disableEmployee}/${state.cpf}`
      : `${endpoints.account.enableUser}/${state.cpf}`,
    ApiMethod.PUT,
  );

  const RequestResetPassword = useApi(
    `${endpoints.register.resetPasswordClient}/${form?.email}`,
    ApiMethod.POST,
  );

  const RequestSave = useApi<IForm>(
    `${endpoints.update.employee}`,
    ApiMethod.PUT,
  );

  const handleResetPassword = async () => {
    const response = await RequestResetPassword.callApi();

    if (response?.status === 200 || response?.status === 204) {
      setMsgDialogEmail('E-mail enviado com sucesso!');
      setDialogResetPassword(false);
      setOpenDialogEmail(true);
      setStatusEmail(true);
    } else if (response?.status === 500) {
      setMsgDialogEmail('Ocorreu um erro ao enviar o e-mail!');
      setOpenDialogEmail(true);
      setStatusEmail(false);
    } else {
      setMsgDialogEmail(response?.mensagem);
      setOpenDialogEmail(true);
      setStatusEmail(false);
    }
  };

  const getUserData = async () => {
    const { data: user } = await GetUserDetail.callApi();
    setForm({
      address: user.endereco.logradouro,
      cep: cepMask(user.endereco.cep),
      city: user.endereco.cidade,
      complement: user.endereco.complemento,
      cpf: cpfMask(user.cpf),
      dateBirth: dateMask(user.dataNascimento?.split('-').reverse().join('/')),
      email: user.email,
      name: user.nome,
      neighborhood: user.endereco.bairro,
      number: user.endereco.numero,
      phone: phoneMask(user.celular),
      idEmpresa: user.idEmpresa,
      perfis: user.perfis,
      ativo: user.ativo,
      idUsuario: user.id,
      bloqueado: user.bloqueado,
      idFuncionario: user?.idFuncionario,
      isClient: !!user?.idCliente,
      isEmployee: !!user?.idFuncionario,
      perfilFuncionario: user?.perfilFuncionario,
    });
  };
  const GetAllProfiles = useApi(
    endpoints.consult.getAllProfiles,
    ApiMethod.GET,
  );
  useEffect(() => {
    (async () => {
      await getUserData();
    })();
  }, []);

  async function handleClickSave() {
    const { data, status } = await RequestSave.callApi({
      celular: form.phone?.unmasked,
      endereco: {
        bairro: form.neighborhood,
        cep: form.cep?.unmasked,
        cidade: form.city,
        complemento: form.complement,
        logradouro: form.address,
        numero: form.number,
      },
      nome: form.name?.trim(),
      email: form.email?.trim(),
      dataNascimento: transformInGlobalDate(form.dateBirth.masked),
      idEmpresa: form.idEmpresa,
      funcionarioId: form.idFuncionario,

      cpf: form.cpf?.unmasked,
    });

    if (status === 200 || status === 201) {
      setDialogMessage('Funcionário editado com sucesso!');
      handleToggleDialog();
    } else if (status === 500) {
      setDialogMessage('Ocorreu um erro ao salvar!');
      handleToggleDialog();
    } else {
      setDialogMessage(data.mensagem);
      handleToggleDialog();
    }
  }

  const handleToggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  const toogleDialogBlock = () => {
    setOpenDialogBlock((prev) => !prev);
  };

  const goBack = () => history.goBack();

  const goToAppAccess = () =>
    history.push('/consult/costumer/appAccess/detail', { ...form });
  const handleBlockUser = async () => {
    const response = await PostBlockUser.callApi();
    setDialogMessage(response?.mensagem ?? 'Usuário bloqueado com sucesso!');
    handleToggleDialog();
    toogleDialogBlock();
  };
  const handleUnblockUser = async () => {
    const response = await PostUnblockUser.callApi();
    setDialogMessage(response?.mensagem ?? 'Usuário desbloqueado com sucesso!');
    handleToggleDialog();
    toogleDialogBlock();
  };

  const handleChange = ({ target: { value, name } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const PostBlockUser = useApi(
    endpoints.account.postBlockUser.replace('PARAM_ID', form.idUsuario),
    ApiMethod.PUT,
  );
  const PostUnblockUser = useApi(
    endpoints.account.postUnblockUser.replace('PARAM_ID', form.idUsuario),
    ApiMethod.PUT,
  );

  const GetAddressByCep = useApi(
    endpoints.consult.getAddressByCep.replace(
      'PARAM_CEP',
      form.cep?.masked?.replace('-', ''),
    ),
    ApiMethod.GET,
  );
  const handleGetCep = async () => {
    const response = await GetAddressByCep.callApi();
    if (response.status >= 200 && response.status <= 300) {
      setForm((prev) => ({
        ...prev,
        address: response.data?.logradouro ?? '',
        neighborhood: response.data?.bairro ?? '',
        city: response.data?.localidade ?? '',
      }));
    }
  };

  useEffect(() => {
    if (form?.cep?.unmasked?.length === 8) {
      handleGetCep();
    }
  }, [form.cep]);

  return (
    <>
      <Loading
        status={
          PutEnableDisableUser.loading ||
          RequestSave.loading ||
          GetUserDetail.loading ||
          GetAllProfiles.loading ||
          PostBlockUser.loading ||
          PostUnblockUser.loading
        }
      />
      <Dialog
        open={openDialogEmail}
        message={msgDialogEmail}
        positiveAction={() => setOpenDialogEmail(false)}
        positiveLabel="Entendi"
        icon={statusEmail ? icons.check : icons.warning}
      />

      <Dialog
        icon={icons.exclamation}
        open={dialogResetPassword}
        negativeLabel="Não"
        negativeAction={() => setDialogResetPassword(false)}
        positiveLabel="Sim"
        positiveAction={() =>
          setModalPasswordOpen({ open: true, type: 'resetPassword' })
        }
        message="Deseja enviar o e-mail para redefinir a senha?"
        Loading={RequestResetPassword.loading}
      >
        {modalPasswordOpen.type === 'resetPassword' ? (
          <PasswordModal
            onClose={() => setModalPasswordOpen({ open: false, type: '' })}
            onSuccessValidation={() => handleResetPassword()}
            Loading={RequestResetPassword.loading}
          />
        ) : null}
      </Dialog>

      <Dialog
        open={openDialog}
        message={dialogMessage}
        positiveAction={async () => {
          await getUserData();
          handleToggleDialog();
        }}
        positiveLabel="Entendi"
        icon={icons.check}
      />

      <Dialog
        open={openDialogBlock}
        widthInPercent={30}
        title={!form.bloqueado ? 'Bloquear acesso' : 'Desbloquear acesso'}
        message={
          <div style={{ textAlign: 'center' }}>
            {!form.bloqueado ? (
              <span>
                O usuário perderá acesso a <b>qualquer aplicação</b>, até que
                seja desbloqueado!
              </span>
            ) : (
              <span>
                O usuário ganhará acesso ao <b>qualquer aplicação</b>, até que
                seja bloqueado!
              </span>
            )}
            <p style={{ marginTop: '2rem' }}>Deseja continuar?</p>
          </div>
        }
        positiveAction={() =>
          form.bloqueado ? handleUnblockUser() : handleBlockUser()
        }
        negativeAction={toogleDialogBlock}
        positiveLabel="Continuar"
        buttonDisabledPositive={
          PostBlockUser.loading || PostUnblockUser.loading
        }
        negativeLabel="Voltar"
        icon
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <Grid xs={1} lg={2} gap={20}>
              <ContainerFlex padding={16}>
                <Input
                  label="CPF"
                  name="cpf"
                  readOnly
                  mask={MaskTypes.CPF}
                  placeholder="000.000.000-00"
                  value={form.cpf?.masked}
                />
                <Input
                  label="Nome completo"
                  name="name"
                  onChange={handleChange}
                  value={form?.name}
                />

                <Input
                  label="Telefone"
                  name="phone"
                  mask={MaskTypes.PHONE}
                  placeholder="(00) 00000-0000"
                  value={form.phone?.masked}
                  onChange={handleChange}
                  maxLength={15}
                />
                <Input
                  label="Data de nascimento"
                  name="dateBirth"
                  mask={MaskTypes.DATE}
                  onChange={handleChange}
                  placeholder="dd/mm/aaaa"
                  value={form.dateBirth?.masked}
                  maxLength={10}
                />
                <Input
                  label="E-mail"
                  name="email"
                  type="email"
                  mask={MaskTypes.EMAIL}
                  value={form?.email?.trim()}
                  style={{ flex: 1 }}
                  onChange={handleChange}
                />
              </ContainerFlex>

              <ContainerFlex padding={16}>
                <Input
                  label="CEP"
                  name="cep"
                  mask={MaskTypes.CEP}
                  placeholder="00000-000"
                  value={form.cep?.masked}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  maxLength={11}
                />
                <Input
                  label="Endereço"
                  name="address"
                  value={form?.address}
                  onChange={handleChange}
                />

                <ContainerFlex row>
                  <ContainerFlex marginRight={16}>
                    <Input
                      label="Número"
                      name="number"
                      value={form?.number}
                      onChange={handleChange}
                    />
                  </ContainerFlex>

                  <ContainerFlex>
                    <Input
                      label="Complemento"
                      name="complement"
                      value={form?.complement}
                      onChange={handleChange}
                    />
                  </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex row>
                  <ContainerFlex marginRight={16}>
                    <Input
                      label="Cidade"
                      name="city"
                      value={form?.city}
                      onChange={handleChange}
                    />
                  </ContainerFlex>

                  <ContainerFlex>
                    <Input
                      label="Bairro"
                      name="neighborhood"
                      value={form.neighborhood}
                      onChange={handleChange}
                    />
                  </ContainerFlex>
                </ContainerFlex>

                <Input
                  label="Perfil de Cliente?"
                  name="perfilCliente"
                  value={form.perfis.includes('USUARIO') ? 'SIM' : 'NÃO'}
                  maxLength={10}
                  disabled
                />
              </ContainerFlex>
            </Grid>
            <ContainerFlex gap="16" row end wrap>
              <Button outlined onClick={goBack}>
                Voltar
              </Button>
              <Button
                outlined={form.bloqueado}
                onClick={toogleDialogBlock}
                disabled={!isAdmin}
              >
                {form?.bloqueado ? 'Desbloquear' : 'Bloquear'}
              </Button>

              <Button
                onClick={() => setDialogResetPassword(true)}
                loading={RequestResetPassword.loading}
                disabled={!isEmployee}
              >
                Redefinir senha APP
              </Button>

              <Button onClick={goToAppAccess}>Histórico de acesso</Button>

              <Button onClick={handleClickSave} disabled={userIsNotAdmin}>
                Salvar
              </Button>

              {!form.isEmployee ? (
                <Button
                  onClick={() =>
                    history.push('/consult/employee/register', {
                      cpf: form.cpf,
                    })
                  }
                  disabled={userIsNotAdmin}
                >
                  Novo funcionário
                </Button>
              ) : null}
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default RegisterOperator;
