/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import moment from 'moment';
import * as S from '../../styles';
import { currencyMask } from '~/utils/masks';
import { detailHeaderConference } from '../../model';

const ListConference: React.FC<any> = ({ data }) => {
  return (
    <>
      <S.MainHeader>Confirmação de antecipação de compra</S.MainHeader>
      <table style={{ width: '100%', borderCollapse: 'separate' }}>
        <S.headerTable>
          <tr>
            {detailHeaderConference.map((item) => (
              <S.hederTitle
                key={item.id}
                maxChar={item.maxChar}
                textAlign={item.textAlign}
              >
                {item.value}
              </S.hederTitle>
            ))}
          </tr>
        </S.headerTable>
        <br />
        <S.bodyTable>
          {data.map((item, index) => (
            <tr>
              <S.bodyData
                key={index}
                maxChar={50}
                textAlign="left"
                title={item.nomeCompleto}
              >
                {item.nomeCompleto}
              </S.bodyData>
              <S.bodyData
                key={index}
                maxChar={50}
                textAlign="center"
                title={String(item.parcelasAAntecipar)}
              >
                {item.parcelasAAntecipar}
              </S.bodyData>
              <S.bodyData
                key={index}
                maxChar={50}
                textAlign="center"
                title={item.dataCompra}
              >
                {moment(item.dataCompra).format('DD/MM/YYYY')}
              </S.bodyData>
              <S.bodyData
                key={index}
                maxChar={50}
                textAlign="center"
                title={String(item.valorAntecipacao)}
              >
                {`R$ ${currencyMask(item.valorAntecipacao)}`}
              </S.bodyData>
            </tr>
          ))}
        </S.bodyTable>
      </table>
    </>
  );
};

export default ListConference;
