import styled from 'styled-components';
import Themes from '~/themes/themes';

export const ContainerCheckBox = styled.div`
  display: flex;
  margin-top: 20px;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

interface IStyledCheckBox {
  checked?: boolean;
}

export const StyledCheckbox = styled.div<IStyledCheckBox>`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${(props) => (props.checked ? '#e71037' : 'white')};
  border-radius: 3px;
  transition: all 150ms;

  border: ${(props) =>
    props.checked ? '1px solid #e71037' : '1px solid #cccc'};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #e71037;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const StCheckboxMessage = styled.span`
  font-weight: 300;
  margin-left: 12px;
  margin-bottom: 2px;
`;

export const InvoiceIcon = styled.span`
  margin-right: 7px;
  color: ${Themes.colors.primary};
`;

export const StCheckboxTermsMessage = styled.span`
  font-size: 12px;
  color: #757575;
  font-weight: 500;
`;
