/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';

import SignIn from '~/views/SignIn';
import Forgot from '~/views/Forgot';
import ForgotNewPass from '~/views/ForgotNewPass';
import ChatList from '~/views/Chat/ChatList';
import ChatRoom from '~/views/Chat/ChatRoom';
import Register from '~/views/Register';
import RegisterOperator from '~/views/RegisterOperator';
import ConsultUser from '~/views/Consult/User';
import ConsultDisable from '~/views/Consult/ConsultDisable';
import ConsultDisableDetail from '~/views/Consult/ConsultDisable/Detail';
import ConsultUserDetail from '~/views/Consult/User/Detail';
import ConsultEmployee from '~/views/Consult/Employee';
import ConsultEmployeeDetail from '~/views/Consult/Employee/Detail';
import ConsultTransaction from '~/views/Consult/Transaction';
import ConsultTransactionDetail from '~/views/Consult/Transaction/Detail';
import ConsultDispute from '~/views/Consult/Dispute';
import ConsultDisputeTable from '~/views/Consult/Dispute/Table';
import DigitalWallet from '~/views/ConsultTransaction/DigitalWallet';
import DigitalWalletDetail from '~/views/ConsultTransaction/DigitalWallet/Detail';
import TeucardCard from '~/views/ConsultTransaction/TeucardCard';
import TeucardCardDetail from '~/views/ConsultTransaction/TeucardCard/Detail';
import ReactivateUser from '~/views/Consult/User/ReactivateUser';
import ConsultProposalCT from '~/views/CreditTable/Proposal';
import DetailProposalCT from '~/views/CreditTable/Proposal/Detail';
import LimitSolicitation from '~/views/CreditTable/LimitSolicitation';
import LimitDecision from '~/views/CreditTable/LimitDecision';
import ConsultLimitCT from '~/views/CreditTable/LimitDecision/Detail';
import DetailLimitCT from '~/views/CreditTable/LimitSolicitation/Detail';
import ConsultReportCT from '~/views/CreditTable/Report';
import DetailReportCT from '~/views/CreditTable/Report/Detail';
import ConsultReactivateDecision from '~/views/CreditTable/ReactivateDecision';
import DetailReactivate from '~/views/CreditTable/ReactivateDecision/Detail';
import Invoice from '~/views/Invoice/index';
import Sales from '~/views/Sales';
import DetailSales from '~/views/Sales/Detail';
import InvoiceDetail from '~/views/Invoice/Detail/index';
import FileUpload from '~/views/Files/Upload';
import FileHistory from '~/views/Files/History';
import FileCharge from '~/views/Files/Charge';
import FilePositive from '~/views/Files/Positive';
import CancelInactivity from '~/views/Files/CancelInactivity';
import ManagementProducts from '~/views/ManagementTools/ManagementProducts';
import ManagementAppHome from '~/views/ManagementTools/ManagementAppHome';
import Report from '~/views/Report/index';
import LimitIncrease from '~/views/LimitIncrease';
import ListClientCampaing from '~/views/LimitIncrease/ListClientCampaing';

import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import ActiveLimits from '~/views/Files/ActiveLimits';
import FileCustomerBase from '~/views/Files/CustomerBase';
import AntecipationInvoice from '~/views/Invoice/AntecipationInvoice';
import AntecipationPurchase from '~/views/Invoice/AntecipationPurchase';
import AntecipationHistory from '~/views/Invoice/AntecipationPurchase/History';
import AntecipationConference from '~/views/Invoice/AntecipationPurchase/AnticipationConference';
import FileProposal from '~/views/Files/Proposal';

import HomeDash from '~/views/HomeDash';
import DashDetailsProposal from '~/views/HomeDash/DashDetailsProposal';
import DashDetailsClientBase from '~/views/HomeDash/DashDetailsClientBase';
import DashDetailsAdhesions from '~/views/HomeDash/DashDetailsAdhesions';
import DashDetailsProposalRealTime from '~/views/HomeDash/DashDetailsProposalRealTime';
import DashInvoiceClosing from '~/views/HomeDash/DashInvoiceClosing';
import DashDetailsInvoicePaid from '~/views/HomeDash/DashDetailsInvoicePaid';
import DashDetailsLateInvoice from '~/views/HomeDash/DashDetailsLateInvoice';
import InvoiceFile from '~/views/Files/InvoiceFile';
import ClientSearch from '~/views/Invoice/ClientSearch';
import PurchaseInclusion from '~/views/Consult/PurchaseInclusion';
import DashDetailsAvailableCredit from '~/views/HomeDash/DashAvailableCredits';
import DashProjection from '~/views/HomeDash/DashProjection';
import DashDetailsBenefitsRealTime from '~/views/HomeDash/DashDetailsBenefitsRealTime';
import ReportBenefit from '~/views/Report/Benefit';
import ReportInvoice from '~/views/Report/Invoice';
import ReportProposal from '~/views/Report/Proposal';
import ReportWallet from '~/views/Report/Wallet';
import ReportCard from '~/views/Report/Card';
import ReportFinancial from '~/views/Report/Financial';
import ReportLoan from '~/views/Report/Loan';
import PurchaseDecision from '~/views/Consult/PurchaseDecision/indext';
import ClientSearchByDecision from '~/views/Invoice/ClientSearchByDecision';
import TransactionDecisionDetail from '~/views/Invoice/ClientSearchByDecision/Detail';
import { logout } from '~/services/auth';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import EventBox from '~/views/Sales/EventsCenter';
import { RequestEvent } from '~/views/Sales/EventsCenter/RequestEvent';
import { SolicitationDetails } from '~/views/Sales/EventsCenter/SolicitationDetails';
import { DowngradeDecision } from '~/views/Sales/EventsCenter/Card/DowngradeDecision';
import { UpgradeSolicitation } from '~/views/Sales/EventsCenter/Card/UpgradeSolicitation';
import { UpgradeDecision } from '~/views/Sales/EventsCenter/Card/UpgradeDecision';
import { LeadSolicitation } from '~/views/Sales/EventsCenter/Benefits/LeadSolicitation';
import { LeadDecision } from '~/views/Sales/EventsCenter/Benefits/LeadDecision';

import { AccountLeadDetails } from '~/views/Sales/EventsCenter/Account/Details';

import HistoryLoan from '~/views/Consult/User/HistoryLoan';

import IndicationLinkList from '~/views/Sales/IndicationLink';
import IndicationLinkCreate from '~/views/Sales/IndicationLink/create';
import IndicationLinkDetails from '~/views/Sales/IndicationLink/details';
import LimitIncreaseCreate from '~/views/LimitIncrease/Create';

import ProposalResume from '~/views/CreditTable/Proposal/proposalResume';
import ReportEvents from '~/views/Report/Events';
import ConsultCostumer from '~/views/Consult/ConsultCostumer';
import ConsultCostumerDetail from '~/views/Consult/ConsultCostumer/Detail';
import AppAccess from '~/views/Consult/ConsultCostumer/Detail/AppAccess';
import FacialBiometricsDetail from '~/views/Consult/FacialBiometrics/detail';
import ConsultFacialBiometrics from '~/views/Consult/FacialBiometrics';
import TeuPagConsult from '~/views/Teupag';
import RegisterCustomerTeuPag from '~/views/Teupag/detail';

import ParametersIndex from '~/views/Parameters';
import EventsCenterParameters from '~/views/Parameters/eventsCenter';

const Routes: React.FC = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  const location = useLocation();
  const pathname = location.pathname as string;

  // Rotas nas quais nao fazemos logout automatico por inatividade.
  const routesDontOFF = ['/'];

  let time;
  let timeMsg;

  // reset timer
  window.onload = resetTimer;
  document.onmouseenter = resetTimer;
  document.onmousemove = resetTimer;
  document.onmousedown = resetTimer;
  document.onmouseover = resetTimer;
  document.onclick = resetTimer;
  document.onkeydown = resetTimer;

  function doSomething() {
    // Passa aqui na inatividade
    setDialogIsOpen(true);
    timeMsg = setTimeout(() => {
      if (isLogout) {
        logout();
      }
    }, 5000);
  }

  function resetTimer() {
    setIsLogout(false);
    clearTimeout(time);
    clearTimeout(timeMsg);
    time = setTimeout(() => {
      if (!routesDontOFF.includes(pathname)) {
        setIsLogout(true);
        doSomething();
      }
    }, 3600000);
  }

  return (
    <>
      <Dialog
        icon={icons.warning}
        positiveLabel="Sim"
        negativeLabel="Não"
        open={dialogIsOpen}
        message="Identificamos que a sessão está sem atividade, deseja continuar?"
        positiveAction={() => {
          setIsLogout(true);
          setDialogIsOpen(false);
          (window.location as any).reload();
          resetTimer();
        }}
        negativeAction={() => {
          setDialogIsOpen(false);
          setIsLogout(false);
          logout();
        }}
      />
      <Switch>
        <PublicRoute path="/" exact component={SignIn} />
        <PublicRoute path="/forgot" component={Forgot} />
        <PublicRoute path="/forgotNewPass" component={ForgotNewPass} />

        <PrivateRoute
          exact
          path="/register/operator"
          component={RegisterOperator}
        />
        <PrivateRoute
          exact
          path="/consult/employee/register"
          component={Register}
        />
        <PrivateRoute path="/homeDash" component={HomeDash} />
        <PrivateRoute
          path="/dashDetailsProposal"
          component={DashDetailsProposal}
        />
        <PrivateRoute
          path="/dashDetailsClientBase"
          component={DashDetailsClientBase}
        />
        <PrivateRoute
          path="/dashDetailsAdhesions"
          component={DashDetailsAdhesions}
        />
        <PrivateRoute
          path="/dashDetailsBenefitsRealTime"
          component={DashDetailsBenefitsRealTime}
        />
        <PrivateRoute
          path="/dashDetailsProposalRealTime"
          component={DashDetailsProposalRealTime}
        />
        <PrivateRoute path="/fechamentoFatura" component={DashInvoiceClosing} />
        <PrivateRoute path="/faturasPagas" component={DashDetailsInvoicePaid} />
        <PrivateRoute
          path="/creditosDisponiveis"
          component={DashDetailsAvailableCredit}
        />
        <PrivateRoute
          path="/faturasEmAtraso"
          component={DashDetailsLateInvoice}
        />

        <PrivateRoute path="/dashProjection" component={DashProjection} />

        <PrivateRoute
          exact
          path="/consult-transaction/digital-wallet"
          component={DigitalWallet}
        />

        <PrivateRoute
          exact
          path="/consult-transaction/digital-wallet/detail"
          component={DigitalWalletDetail}
        />

        <PrivateRoute
          exact
          path="/consult/transaction"
          component={ConsultTransaction}
        />
        <PrivateRoute
          exact
          path="/consult/transaction/detail"
          component={ConsultTransactionDetail}
        />
        <PrivateRoute
          exact
          path="/consult/disable"
          component={ConsultDisable}
        />
        <PrivateRoute
          exact
          path="/consult/disable/detail"
          component={ConsultDisableDetail}
        />
        <PrivateRoute exact path="/consult/user" component={ConsultUser} />
        <PrivateRoute
          exact
          path="/consult/user/detail"
          component={ConsultUserDetail}
        />

        <PrivateRoute
          exact
          path="/consult/user/history-loan"
          component={HistoryLoan}
        />

        <PrivateRoute
          exact
          path="/consult/user/biometrics/detail"
          component={FacialBiometricsDetail}
        />

        <PrivateRoute
          exact
          path="/consult/user/biometrics"
          component={ConsultFacialBiometrics}
        />

        <PrivateRoute
          exact
          path="/consult/employee"
          component={ConsultEmployee}
        />
        <PrivateRoute
          exact
          path="/consult/employee/detail"
          component={ConsultEmployeeDetail}
        />
        <PrivateRoute
          exact
          path="/consult/costumer/appAccess/detail"
          component={AppAccess}
        />

        <PrivateRoute
          exact
          path="/consult/costumer"
          component={ConsultCostumer}
        />
        <PrivateRoute
          exact
          path="/consult/costumer/detail"
          component={ConsultCostumerDetail}
        />
        <PrivateRoute
          exact
          path="/consult/reactivate-user/detail"
          component={ReactivateUser}
        />

        <PrivateRoute
          exact
          path="/credit-table/proposal"
          component={ConsultProposalCT}
        />
        <PrivateRoute
          exact
          path="/credit-table/proposal/detail"
          component={DetailProposalCT}
        />
        <PrivateRoute
          exact
          path="/credit-table/limit/solicitation"
          component={LimitSolicitation}
        />
        <PrivateRoute
          exact
          path="/credit-table/limit/decision"
          component={LimitDecision}
        />
        <PrivateRoute
          exact
          path="/credit-table/limit/solicitation/detail"
          component={DetailLimitCT}
        />
        <PrivateRoute
          exact
          path="/credit-table/limit/decision/detail"
          component={ConsultLimitCT}
        />
        <PrivateRoute
          exact
          path="/credit-table/proposal/proposal-resume"
          component={ProposalResume}
        />

        <PrivateRoute
          exact
          path="/credit-table/report"
          component={ConsultReportCT}
        />
        <PrivateRoute
          exact
          path="/credit-table/report/detail"
          component={DetailReportCT}
        />
        <PrivateRoute
          exact
          path="/credit-table/reactivate/decision"
          component={ConsultReactivateDecision}
        />
        <PrivateRoute
          exact
          path="/credit-table/reactivate/decision/detail"
          component={DetailReactivate}
        />
        <PrivateRoute
          exact
          path="/consult/dispute"
          component={ConsultDispute}
        />
        <PrivateRoute
          exact
          path="/consult/dispute/table"
          component={ConsultDisputeTable}
        />
        <PrivateRoute exact path="/report/others" component={Report} />
        <PrivateRoute exact path="/report/benefit" component={ReportBenefit} />
        <PrivateRoute exact path="/report/wallet" component={ReportWallet} />
        <PrivateRoute exact path="/report/invoice" component={ReportInvoice} />
        <PrivateRoute
          exact
          path="/report/financial"
          component={ReportFinancial}
        />
        <PrivateRoute exact path="/report/card" component={ReportCard} />
        <PrivateRoute exact path="/report/loan" component={ReportLoan} />
        <PrivateRoute exact path="/report/events" component={ReportEvents} />
        <PrivateRoute
          exact
          path="/report/proposal"
          component={ReportProposal}
        />
        <PrivateRoute exact path="/sales" component={Sales} />
        <PrivateRoute exact path="/sales/detail" component={DetailSales} />
        <PrivateRoute exact path="/teu-pag" component={TeuPagConsult} />
        <PrivateRoute
          exact
          path="/teu-pag/detail"
          component={RegisterCustomerTeuPag}
        />
        <PrivateRoute exact path="/file/upload" component={FileUpload} />
        <PrivateRoute exact path="/file/history" component={FileHistory} />
        <PrivateRoute exact path="/file/charge" component={FileCharge} />
        <PrivateRoute exact path="/file/positive" component={FilePositive} />
        <PrivateRoute
          exact
          path="/file/cancel-inactivity"
          component={CancelInactivity}
        />
        <PrivateRoute exact path="/file/proposal" component={FileProposal} />
        <PrivateRoute
          exact
          path="/file/activeLimits"
          component={ActiveLimits}
        />
        <PrivateRoute
          exact
          path="/file/customer-base"
          component={FileCustomerBase}
        />
        <PrivateRoute
          exact
          path="/management-tools/products"
          component={ManagementProducts}
        />
        <PrivateRoute
          exact
          path="/management-tools/personalize-app-home"
          component={ManagementAppHome}
        />
        <PrivateRoute exact path="/file/invoice-file" component={InvoiceFile} />
        <PrivateRoute exact path="/invoice" component={Invoice} />
        <PrivateRoute exact path="/invoice/detail" component={InvoiceDetail} />
        <PrivateRoute
          exact
          path="/invoice/antecipation"
          component={AntecipationInvoice}
        />
        <PrivateRoute
          exact
          path="/invoice/antecipation/purchase"
          component={AntecipationPurchase}
        />
        <PrivateRoute
          exact
          path="/invoice/antecipation/conference"
          component={AntecipationConference}
        />
        <PrivateRoute
          exact
          path="/invoice/purchase-inclusion"
          component={ClientSearch}
        />
        <PrivateRoute
          exact
          path="/invoice/purchase-inclusion/detail"
          component={PurchaseInclusion}
        />
        <PrivateRoute
          exact
          path="/invoice/purchase-decision"
          component={ClientSearchByDecision}
        />
        <PrivateRoute
          exact
          path="/invoice/purchase-decision/detail"
          component={TransactionDecisionDetail}
        />
        <PrivateRoute
          exact
          path="/invoice/antecipation/purchase/history"
          component={AntecipationHistory}
        />
        <PrivateRoute exact path="/sales/eventCenter" component={EventBox} />
        <PrivateRoute
          exact
          path="/sales/eventCenter/requestEvent"
          component={RequestEvent}
        />
        <PrivateRoute
          exact
          path="/sales/eventCenter/downgradeDecision"
          component={DowngradeDecision}
        />
        <PrivateRoute
          exact
          path="/sales/eventCenter/SolicitationDetails"
          component={SolicitationDetails}
        />
        <PrivateRoute
          exact
          path="/sales/eventCenter/upgradeSolicitation"
          component={UpgradeSolicitation}
        />
        <PrivateRoute
          exact
          path="/sales/eventCenter/upgradeDecision"
          component={UpgradeDecision}
        />
        <PrivateRoute
          exact
          path="/sales/eventCenter/leadSolicitation"
          component={LeadSolicitation}
        />
        <PrivateRoute
          exact
          path="/sales/eventCenter/leadDecision"
          component={LeadDecision}
        />
        <PrivateRoute
          exact
          path="/sales/eventCenter/accountLead/details"
          component={AccountLeadDetails}
        />
        <PrivateRoute path="/chat/room" component={ChatRoom} />
        <PrivateRoute path="/chat" component={ChatList} />
        <PrivateRoute path="/campanha" component={LimitIncrease} />
        <PrivateRoute path="/criar-campanha" component={LimitIncreaseCreate} />
        <PrivateRoute
          path="/lista-cliente-campanha"
          component={ListClientCampaing}
        />

        <PrivateRoute
          path="/sales/indicationLink"
          component={IndicationLinkList}
        />
        <PrivateRoute
          path="/sales/indicationLinkCreate"
          component={IndicationLinkCreate}
        />
        <PrivateRoute
          path="/sales/indicationLinkEdit"
          component={IndicationLinkDetails}
        />

        {/* PARAMETROS */}
        <PrivateRoute path="/parameters/all" component={ParametersIndex} />
        <PrivateRoute
          path="/parameters/events-center"
          component={EventsCenterParameters}
        />

        <Redirect to="/homeDash" />
      </Switch>
    </>
  );
};

export default Routes;
