import styled from 'styled-components';

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin-bottom: 24px;
`;

export const Title = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;

export const Info = styled.span`
  font-size: 1rem;
  font-weight: 400;
`;

export const IconExclamation = styled.img`
  width: 1.5%;
  height: 1.5%;
  margin-top: 1%;
`;

export const DescriptionInfo = styled.h3`
  color: #e71037;
  text-align: center;
  width: 100%;
  /* max-width: 400px; */
  margin: 20px auto 0 auto;
`;
