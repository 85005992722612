/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';
import { logout } from '~/services/auth';
import Constants from '~/utils/constants';
import { CustomizedError } from '~/utils/errors';

export enum ApiMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

function useApi<T>(
  url: string,
  method: ApiMethod,
  body = {},
  tryNewWay = false,
  headers = {},
  multipart = false,
) {
  const [responseApi, setResponseApi] = useState({
    status: null,
    loading: false,
    error: null,
    data: null,
    success: false,
    response: null,
    mensagem: null,
  });
  const callApi = (reqBody = {}) => FetchData(reqBody);

  const getFormData = (object) => {
    return Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
  };

  const getOptions = (reqBody = {}) => {
    const reqHeaders: Headers = new Headers();
    const token = localStorage.getItem(Constants.TOKEN_KEY);
    const tokenBearer = localStorage.getItem(Constants.TOKEN_BEARER);
    const ipAdress = localStorage.getItem(Constants.IP_ADRESS);
    const { userAgent } = navigator;
    const sessionId = localStorage.getItem(Constants.SESSION_ID);
    const xCode = localStorage.getItem(Constants.X_CODE);
    const idOperator = localStorage.getItem(Constants.USER_ID);

    if (sessionId) reqHeaders.set('Id-Sessao', sessionId);
    reqHeaders.set('Id-Sistema', `${process.env.REACT_APP_ID_SISTEMA}`);
    if (userAgent) reqHeaders.set('User-Agent', userAgent);
    if (xCode) reqHeaders.set('X-Code', xCode);
    if (ipAdress) reqHeaders.set('X-Sent-By', ipAdress);
    if (idOperator) reqHeaders.set('Id-User', idOperator);
    reqHeaders.set('Content-Type', 'application/json');
    if (token) reqHeaders.set('apikey', `token ${token}`);
    if (tokenBearer) reqHeaders.set('Authorization', `Bearer ${tokenBearer}`);

    if (!multipart) {
      reqHeaders.set('Content-Type', 'application/json');
    }

    if (headers) Object.assign(reqHeaders, headers);
    let newBody = reqBody || body;

    if (!Array.isArray(body) && !Array.isArray(reqBody)) {
      newBody = Object.assign(body, reqBody);
    }
    let options: RequestInit = {
      method,
      headers: reqHeaders,
    };
    if (method !== ApiMethod.GET) {
      if (multipart) {
        options = {
          ...options,
          body: getFormData(newBody),
        };
        delete options.headers['Content-Type'];
      } else {
        options = {
          ...options,
          body: JSON.stringify(newBody) || null,
        };
      }
    }
    return options;
  };

  const FetchData = async (
    reqBody = {},
  ): Promise<{
    status: number;
    data: object & T;
    response: Response;
    mensagem?: string;
  }> => {
    setResponseApi({ ...responseApi, loading: true });

    const options = getOptions(reqBody);
    let copyResponse;
    let json = null;
    let newUrl = url;
    let res: Response;
    try {
      // transforma um objeto em query params no metodo GET
      if (options.method === ApiMethod.GET && tryNewWay) {
        // exclui parametros vazios, nulos ou undefined
        Object.keys(reqBody).forEach((e) => {
          if (
            reqBody[e] === '' ||
            reqBody[e] === undefined ||
            reqBody[e] === null
          ) {
            delete reqBody[e];
          }
        });
        const bodyParams = new URLSearchParams(reqBody).toString();
        newUrl = `${url}?${bodyParams}`;
        res = await fetch(newUrl, options);
      } else {
        res = await fetch(url, options);
      }
      copyResponse = res.clone();
      const statusResponse = res.status;

      if (res.status === 401) {
        logout();
      }

      if (res.status === 403) {
        throw CustomizedError({
          message: 'Você não possui permissão para prosseguir.',
        });
      }

      const contentType = res.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        json = await res.json();
      }

      if (
        (contentType && contentType.indexOf('text/csv;charset=UTF-8')) !== -1
      ) {
        json = await res.blob();
      }

      if (
        contentType &&
        contentType.indexOf('application/vnd.ms-excel') !== -1
      ) {
        json = await res.blob();
      }

      setResponseApi({
        loading: false,
        data: json,
        error: null,
        status: statusResponse,
        success: true,
        response: res,
        mensagem: json ? json.mensagem : '',
      });
    } catch (err) {
      setResponseApi({
        loading: false,
        data: null,
        error: err.message,
        status: copyResponse.status,
        success: false,
        response: null,
        mensagem: null,
      });
    }

    return {
      status: copyResponse.status,
      data: json,
      response: copyResponse,
      mensagem: json ? json.mensagem : '',
    };
  };

  return { callApi, ...responseApi };
}

export default useApi;
