/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { ISharedServiceBenefitContextProps } from '../../Interfaces/benefitInterfaces';

export const SharedBenefitServiceContext = createContext<ISharedServiceBenefitContextProps>(
  {
    searchUser: () => {},
    getContractedBenefitsClient: () => {},
    getNotContractedBenefitsClient: () => {},
    contractedBenefits: [],
    contracted: [],
    notContracted: [],
    notContractedBenefits: [],
    handleSelectBenefits: (selectedBenefits: any) => {},
    loadingBenefit: {
      getContractedBenefitsClient: { loading: false },
      getNotContractedBenefitsClient: { loading: false },
      postRequestSale: { loading: false },
      loadingDecisionBenefit: { loading: false },
    },
    handleRequestSale: () => {},
    decisionService: () => {},
    handlePostLeadDecision: (action: boolean) => {},
    resetBenefitContext: () => {},
    totalValue: () => {},
    handleAtribuir: async () => {},
    handleDesatribuir: async () => {},
  },
);
