import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  disabled?: boolean;
  heightWhenOpen?: string;
}

export const ContainerOptions = styled.div<ContainerProps>`
  position: absolute;
  z-index: 250;
  width: 100%;
  height: ${({ isFocused, heightWhenOpen }) =>
    isFocused ? heightWhenOpen ?? '170px' : '0px'};
  transition: height 0.6s;
  overflow-y: hidden;
  top: 65px;
  background: #fff;
  border-radius: 0 0 0 5px;
  box-shadow: 0px 1px 7px 0px rgba(201, 201, 201, 0.5);

  .scrollable {
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #ddd;
      margin-top: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: #e4003a;
      border: 3px solid #e4003a;
    }
  }

  button {
    padding: 10px 10px;
    background: none;
    text-align: left;
    border: none;
    outline: none;
    box-shadow: none;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    /* background-color: #f3f3f3; */
  }
  button:hover {
    background: #f1f1f1;
  }
  .optionDisabled {
    padding: 10px 10px;
    pointer-events: none;

    :hover {
      background: #f1f1f1;
    }
  }
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  width: -webkit-fill-available;
  /* margin-bottom: 22px; */
  color: #4a4b4a;
  font-size: 1rem !important;
  pointer-events: ${({ disabled }) => disabled && 'none'};

  label {
    font-size: 12px;
    color: #757575;
    /* margin-bottom: 10px; */
    ${(props) =>
      props.isFocused &&
      css`
        color: #e71037;
      `}
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: -15px;
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
`;
