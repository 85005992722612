/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaUpload } from 'react-icons/fa';
import Collapse from '~/components/collapse';
import Container from '~/components/container';
import ClientData from '../../Components/ClientData';
import * as St from '../../Services/styles';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import SharedServiceContext from '../../Services/sharedServiceContext';
import Input from '../../Components/input';
import TextArea from '~/components/textarea';
import { LeadContext } from '~/contexts/leads';
import { SharedBenefitServiceContext } from '../Services/sharedBenefitServiceContext';
import TableList from '../../Components/tableList';
import { headerBenefitDetailsDecision } from '../../Headers/benefitHeaders';
import Loading from '~/components/loading';
import Constants from '~/utils/constants';

export const LeadDecision = () => {
  const history = useHistory();
  const { state } = useLocation<any>();

  const { leads } = useContext(LeadContext);
  const { leadDetail } = leads;
  const {
    cpfGlobal,
    setCpfGlobal,
    cardInfos,
    availableProducts,
    setOpinion,
    setEventId,
    setProduct,
    setEvent,
    loadingGlobal,
    opinion,
    fileName,
    setFileName,
    setFile,
    file,
    disabledButton,
    product,
    event,
    getProfiles,
    getClientInformation,
    getAvailableProducts,
    getInfoCards,
    getPhoto,
    eventId,
    resetContext,
    clientData,
    handleAtribuir,
    handleDesatribuir,
  } = useContext(SharedServiceContext);
  const {
    decisionService,
    contractedBenefits,
    handlePostLeadDecision,
    loadingBenefit,
  } = useContext(SharedBenefitServiceContext);
  const [disabledUpgrade, setDisabledUpgrade] = useState(false);
  const [typeButton, setTypeButton] = useState<string>('');
  const inputFileRef = useRef(null);
  console.log('LEAD DETAIL', leadDetail);
  useEffect(() => {
    return () => {
      resetContext();
    };
  }, []);

  useEffect(() => {
    if (opinion?.length <= 0 || !file || !fileName) {
      setDisabledUpgrade(true);
    } else {
      setDisabledUpgrade(false);
    }
  }, [opinion, file, fileName]);

  const [typeDistribution, setTypeDistribution] = useState<
    'AUTOMATICA' | 'MANUAL'
  >('');
  useEffect(() => {
    if (state) {
      setCpfGlobal(state?.item?.cpf);
      setEventId(state?.item?.id);
      setProduct({ id: 'BENEFICIO', value: state?.item?.produto?.value });
      setEvent(state?.item?.tipoEvento);
      resetarInputFile();
      setTypeDistribution(state?.item?.tipoDistribuicao);
    }
  }, [state]);

  useEffect(() => {
    if (cpfGlobal) {
      getClientInformation();
      getAvailableProducts();
      getInfoCards();
      getPhoto();
    }
  }, [cpfGlobal]);

  const resetarInputFile = () => {
    if (inputFileRef.current !== null) {
      setFile(undefined);
    }
  };

  const toFoward = () => {
    getProfiles();
  };

  useEffect(() => {
    if (eventId && clientData) decisionService();
  }, [eventId, clientData]);

  useEffect(() => {
    if (typeButton === 'ACCEPT') {
      setTypeButton('');
      handlePostLeadDecision(true);
    }
  }, [typeButton]);

  useEffect(() => {
    if (typeButton === 'REJECT') {
      setTypeButton('');
      handlePostLeadDecision(false);
    }
  }, [typeButton]);

  return (
    <>
      <Loading status={loadingGlobal.getUserInfo.loading} />
      <Container>
        <St.BoxCard>
          <Collapse collapseHeight={70} initialState="open" title="1. Cliente">
            <ClientData
              solicitation
              cardInfos={cardInfos}
              availableProducts={availableProducts}
              eventStart
              product={product}
              event={event}
            />
          </Collapse>
          <Collapse title="2. Detalhes da solicitação de venda" typeEvent>
            <TableList
              customerHeader={headerBenefitDetailsDecision}
              list={contractedBenefits}
              checkbox
              defaultAllChecked
            />
            <ContainerFlex row space gap="20">
              <ContainerFlex>
                <Input
                  label="Solicitante"
                  name="solicitante"
                  value={leadDetail?.solicitadorVenda?.nome}
                />
                <Input
                  label="Tipo de Produto"
                  name="produto"
                  value={product?.value}
                />
              </ContainerFlex>
              <ContainerFlex>
                <ContainerFlex row gap="10">
                  <Input
                    label="Data solicitação"
                    name="dtSolicitacao"
                    value={new Date(
                      leadDetail?.dataInclusao,
                    )?.toLocaleDateString('pt-br')}
                  />
                  <Input
                    label="Hora solicitação"
                    name="hrSolicitacao"
                    value={new Date(
                      leadDetail?.dataInclusao,
                    )?.toLocaleTimeString('pt-br')}
                  />
                </ContainerFlex>
                <ContainerFlex>
                  <Input
                    label="Tipo de Evento"
                    name="tipoEvento"
                    value={event?.value}
                  />
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
          </Collapse>
          <Collapse collapseHeight={50} title="3. Decisão">
            <ContainerFlex padding={20}>
              <TextArea
                placeholder="Digite o parecer..."
                style={{ fontStyle: 'italic' }}
                rows={10}
                value={opinion}
                onChange={(e) => setOpinion(e.target.value)}
              />
            </ContainerFlex>
          </Collapse>
          <ContainerFlex marginTop={20} row space>
            <ContainerFlex row center gap="20">
              <span style={{ fontWeight: 600, fontSize: '18px' }}>
                Gravação da venda
              </span>
              <input
                ref={inputFileRef}
                style={{ display: 'none' }}
                type="file"
                accept="audio/wav, audio/wave, audio/x-wav"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
              <Button size="small" onClick={() => inputFileRef.current.click()}>
                <FaUpload size={16} color="white" />
              </Button>
            </ContainerFlex>
            <ContainerFlex row>
              <Input
                label="Nome do arquivo"
                name="nomeArquivo"
                onChange={(e) => setFileName(e.target.value)}
              />
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex>
            <span style={{ fontWeight: 500, fontSize: '14px' }}>
              {file?.name ?? ''}
            </span>
          </ContainerFlex>

          {typeDistribution === 'MANUAL' ? (
            !leadDetail?.responsavel?.idOperador ? (
              <ContainerFlex row gap="10" end marginTop={20}>
                <Button
                  outlined
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Voltar
                </Button>
                <Button
                  disabled={
                    loadingGlobal.atribuir.loading ||
                    leadDetail?.solicitadorVenda?.idOperador ===
                      localStorage.getItem(Constants.USER_ID)
                  }
                  loading={loadingGlobal.atribuir.loading}
                  onClick={async () => {
                    await handleAtribuir();
                    decisionService();
                  }}
                >
                  Atribuir
                </Button>
              </ContainerFlex>
            ) : (
              <ContainerFlex row gap="10" end marginTop={20}>
                <Button
                  outlined
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Voltar
                </Button>
                {leadDetail?.responsavel?.idOperador && (
                  <Button
                    outlined
                    disabled={
                      leadDetail?.responsavel?.idOperador !==
                        localStorage.getItem(Constants.USER_ID) ||
                      loadingGlobal.desatribuir.loading
                    }
                    loading={loadingGlobal.desatribuir.loading}
                    onClick={async () => {
                      await handleDesatribuir();
                      decisionService();
                    }}
                  >
                    Desatribuir
                  </Button>
                )}

                <Button
                  outlined
                  disabled={
                    disabledButton || loadingGlobal.postForwardLead.loading
                  }
                  loading={loadingGlobal.postForwardLead.loading}
                  onClick={() => {
                    toFoward();
                  }}
                >
                  Encaminhar
                </Button>
                <Button
                  outlined
                  disabled={disabledUpgrade}
                  loading={loadingBenefit.loadingDecisionBenefit.loading}
                  onClick={() => {
                    setTypeButton('REJECT');
                  }}
                >
                  Recusar
                </Button>

                <Button
                  disabled={
                    disabledUpgrade ||
                    loadingBenefit.loadingDecisionBenefit.loading
                  }
                  loading={loadingBenefit.loadingDecisionBenefit.loading}
                  onClick={() => setTypeButton('ACCEPT')}
                >
                  Aprovar
                </Button>
              </ContainerFlex>
            )
          ) : null}

          {typeDistribution === 'AUTOMATICA' && (
            <ContainerFlex row gap="10" end marginTop={20}>
              <Button
                outlined
                onClick={() => {
                  history.goBack();
                }}
              >
                Voltar
              </Button>

              <Button
                outlined
                disabled={
                  disabledButton || loadingGlobal.postForwardLead.loading
                }
                loading={loadingGlobal.postForwardLead.loading}
                onClick={() => {
                  toFoward();
                }}
              >
                Encaminhar
              </Button>
              <Button
                outlined
                disabled={disabledUpgrade}
                loading={loadingBenefit.loadingDecisionBenefit.loading}
                onClick={() => {
                  setTypeButton('REJECT');
                }}
              >
                Recusar
              </Button>

              <Button
                disabled={
                  disabledUpgrade ||
                  loadingBenefit.loadingDecisionBenefit.loading
                }
                loading={loadingBenefit.loadingDecisionBenefit.loading}
                onClick={() => setTypeButton('ACCEPT')}
              >
                Aprovar
              </Button>
            </ContainerFlex>
          )}
        </St.BoxCard>
      </Container>
    </>
  );
};
