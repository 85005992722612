/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { MapContainer, TileLayer, useMap, Popup, Marker } from 'react-leaflet';
import Modal from 'reactjs-popup';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';

import { Icon, LatLngExpression } from 'leaflet';
import { CSVLink } from 'react-csv';
import { icons } from '~/assets';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Input, { MaskTypes } from '~/components/newInput';
import Loading from '~/components/loading';
import { useAuth } from '~/hooks/contexts/useAuth';
import useApi, { ApiMethod } from '~/hooks/useApi';
import { IEmployee } from '~/models/employee';
import endpoints from '~/services/endpoints';
import Constants from '~/utils/constants';
import { cpfMask, dateMask } from '~/utils/masks';
import * as St from '../styles';
import { IListColCampaing } from '~/views/LimitIncrease/model';
import TableList from '~/components/tableList';
import 'leaflet/dist/leaflet.css';
import 'reactjs-popup/dist/index.css';
import Pagination from '~/components/pagination';

interface IForm {
  de: string | Date;
  ate: string | Date;
}
export interface AccessHistoricResponse {
  data: {
    dataCriacao: string;
    deviceId: string;
    geolocation: Geolocation;
    id: string;
    ip: string;
  }[];
  origem: string;
  page: number;
  pageSize: number;
  totalPages: number;
  totalSize: number;
}

export interface Geolocation {
  latitude: number;
  longitude: number;
}
const csvHeaders = [
  { label: 'Data', key: 'dataCriacao' },
  { label: 'Dispositivo', key: 'deviceId' },
  { label: 'IP', key: 'ip' },
  { label: 'Origem', key: 'origem' },
  { label: 'Latitude', key: 'geolocation.latitude' },
  { label: 'Longitude', key: 'geolocation.longitude' },
];
const AppAccess: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogError, setOpenDialogError] = useState(false);
  const [latLng, setLatLng] = useState<LatLngExpression>([]);
  const [dialogMessage, setDialogMessage] = useState('');
  const admin = JSON.parse(localStorage.getItem(Constants.USER_ROLE));

  const [list, setList] = useState([]);
  const { state = {} } = useLocation<IEmployee>();

  const history = useHistory();
  const { user } = useAuth();
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');

  const [form, setForm] = useState<IForm>({
    de: dateMask(moment().format('DD/MM/YYYY')),
    ate: dateMask(moment().format('DD/MM/YYYY')),
  });

  const customerHeader = [
    {
      id: 1,
      value: 'Data',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'Hora',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Dispositivo',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Endereço IP',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Origem',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Localização',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const handleToggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };
  const GetHistory = useApi<AccessHistoricResponse>(
    endpoints.consult.getUserAppAccessHistory.replace(
      'PARAM_ID_USUARIO',
      state?.idUsuario,
    ),
    ApiMethod.GET,
    {},
    true,
  );
  console.log(form);

  const [relatorioData, setRelatorioData] = useState([]);
  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;

    if (currentPage === 1) setDownloadCVSProgress('0%');
    const response = await getLimitHistory(currentPage, 150);

    if (response?.data) {
      const { data } = response;

      totalPages = response.totalPages;

      const porcent = (currentPage / totalPages) * 100;
      const roundPorcent = Math.ceil(porcent);
      setDownloadCVSProgress(`${roundPorcent}%`);

      if (data?.length) {
        setRelatorioData((prev) => [...prev, ...data]);
      }
      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }
    } else {
      setRelatorioData([]);
    }
  };
  console.log(list);
  const getLimitHistory = async (page = 1, limit = 10) => {
    const payload = {
      limit,
      page: page ?? 1,
      dataInicial: form.de.masked.split('/').reverse().join('-'),
      dataFinal: form.ate.masked.split('/').reverse().join('-'),
    };

    console.log(payload);
    const response = await GetHistory.callApi(payload);
    setList([]);
    const newList = [];
    if (response.status === 200) {
      response.data?.data.forEach((acc) => {
        const item = [
          {
            id: 1,
            border: true,
            title: 'Data',
            value: moment(acc.dataCriacao).format('DD/MM/YYYY'),
            maxChar: 20,
          },
          {
            id: 2,
            border: true,
            title: 'Hora',
            value: moment(acc.dataCriacao).format('HH:mm:ss'),
            maxChar: 15,
            textAlign: 'center',
          },
          {
            id: 3,
            border: true,
            title: 'deviceId',
            value: acc.deviceId,
            maxChar: 15,
            textAlign: 'center',
          },
          {
            id: 4,
            border: true,
            title: 'Perfis',
            value: acc.ip,
            maxChar: 10,
            textAlign: 'center',
          },
          {
            id: 5,
            border: true,
            title: 'Origem',
            value: acc.origem,
            maxChar: 10,
            textAlign: 'center',
          },
          {
            id: 6,
            item: acc,
            searchAction: () => {
              if (acc?.geolocation?.latitude) {
                setLatLng([
                  acc?.geolocation?.latitude ?? 0,
                  acc?.geolocation?.longitude ?? 0,
                ]);
                setOpenDialog(true);
              } else if (!acc?.geolocation?.latitude) {
                setOpenDialogError(true);
                setDialogMessage('Localização indisponível');
              }
            },
          },
        ];
        newList.push(item);
      });
      setList(newList);
    }
    return response.data;
  };
  const refCSV = useRef<any>(null);

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleDisableConsultButton = () =>
    form.de?.masked?.length !== 10 || form.ate?.masked?.length !== 10;

  const showMap = useMemo(() => {
    return (
      <MapContainer
        id="map"
        style={{ height: '400px' }}
        center={latLng}
        zoom={20}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          icon={
            new Icon({
              iconUrl: markerIconPng,
              iconSize: [25, 41],
              iconAnchor: [12, 41],
            })
          }
          alt="marcador"
          draggable={false}
          position={latLng}
        >
          <Popup>Localização</Popup>
        </Marker>
      </MapContainer>
    );
  }, [latLng]);
  return (
    <>
      <Loading status={GetHistory.loading} text={downloadCVSProgress} />
      <Modal
        position="center center"
        closeOnDocumentClick
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      >
        <ContainerFlex row center horizontal>
          <p style={{ fontWeight: 'bold', fontSize: '18px', margin: 8 }}>
            Localização
          </p>
        </ContainerFlex>
        {latLng.length > 0 && showMap}
        <ContainerFlex row end marginBottom={8} marginTop={16}>
          <Button onClick={handleToggleDialog}>voltar</Button>
        </ContainerFlex>
      </Modal>
      <Dialog
        title="Localização"
        open={openDialogError}
        icon={icons.warning}
        positiveLabel="Entendi"
        positiveAction={() => setOpenDialogError(false)}
        message={dialogMessage}
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="De"
                name="de"
                value={form.de?.masked}
                mask={MaskTypes.DATE}
                onChange={handleChange}
                maxLength={10}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="Até"
                name="ate"
                mask={MaskTypes.DATE}
                value={form.ate?.masked}
                onChange={handleChange}
                maxLength={10}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <ContainerFlex row space gap="8">
                <ContainerFlex>
                  <Button
                    fitContent
                    onClick={(e) => getLimitHistory()}
                    disabled={handleDisableConsultButton()}
                    fullWidth
                  >
                    <span>Consultar</span>
                  </Button>
                </ContainerFlex>
                <ContainerFlex>
                  <Button outlined onClick={() => history.goBack()}>
                    Voltar
                  </Button>
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex row end>
            {GetHistory.data?.data?.length > 0 && (
              <Button
                onClick={() => {
                  if (refCSV.current) {
                    onClickDownloadCSV().then(() =>
                      refCSV.current.link.click(),
                    );
                  }
                }}
              >
                <span>Download</span>
              </Button>
            )}
          </ContainerFlex>
          <CSVLink
            ref={refCSV}
            data={relatorioData}
            style={{
              color: 'inherit',
              textDecoration: 'none',
            }}
            headers={csvHeaders}
            filename={`acesso_app${state?.idUsuario}.csv`}
            target="_blank"
          />
        </St.BoxCard>

        <St.BoxCard>
          <ContainerFlex row padding={16}>
            {/* {(RequestName.status === 200 || RequestCPF.status === 200) &&
              !list.length ? (
                <ContainerFlex center marginTop={30}>
                  <span>Usuário não localizado</span>
                </ContainerFlex>
              ) : null} */}
            {/* {userList && userList.map((i) => <ListRow key={i} item={i} />)} */}
            {list.length > 0 ? (
              <TableList customerHeader={customerHeader} list={list} />
            ) : (
              <ContainerFlex center marginTop={30}>
                <span>Sem registros</span>
              </ContainerFlex>
            )}
          </ContainerFlex>
          {GetHistory.status === 200 && GetHistory?.data?.totalPages > 1 && (
            <ContainerFlex row horizontal center marginTop={20}>
              <Pagination
                totalPages={GetHistory?.data?.totalPages}
                onCurrentPageChange={(e) => getLimitHistory(e)}
              />
            </ContainerFlex>
          )}
        </St.BoxCard>
      </Container>
    </>
  );
};

export default AppAccess;
