import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  disabled?: boolean;
  heightWhenOpen?: string;
}
export const PopoverContainer = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 1000;
  height: 200px;
  transform: ${({ anchorOrigin }) => {
    const { vertical, horizontal } = anchorOrigin;
    let translateX = '0%';
    let translateY = '0%';

    if (horizontal === 'center') {
      translateX = '0%';
    } else if (horizontal === 'right') {
      translateX = '-100%';
    }

    if (vertical === 'center') {
      translateY = '-50%';
    } else if (vertical === 'bottom') {
      translateY = '0%';
    }

    return `translate(${translateX}, ${translateY})`;
  }};
  > div {
    height: 100%;
    overflow-y: scroll;
    cursor: pointer;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #ddd;
      margin-top: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: #e4003a;
      border: 3px solid #e4003a;
    }
  }

  .option {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    :hover {
      background: #f1f1f1;
    }
  }
  .optionDisabled {
    padding: 10px 10px;
    pointer-events: none;
    :hover {
      background: #f1f1f1;
    }
  }
`;
// export const PopoverContainer = styled.div`
//   position: absolute;
//   background: white;
//   border: 1px solid #ccc;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   padding: 16px;
//   z-index: 1000;
//   height: 200px;
//   overflow: scroll;
//   transform: ${({ anchorOrigin }) => {
//     const { vertical, horizontal } = anchorOrigin;
//     let translateX = '0%';
//     let translateY = '0%';

//     if (horizontal === 'center') {
//       translateX = '0%';
//     } else if (horizontal === 'right') {
//       translateX = '-100%';
//     }

//     if (vertical === 'center') {
//       translateY = '-50%';
//     } else if (vertical === 'bottom') {
//       translateY = '-100%';
//     }

//     return `translate(${translateX}, ${translateY})`;
//   }};
// `;
export const ContainerOptions = styled.div<ContainerProps>`
  position: absolute;
  z-index: 50;
  width: 100%;
  height: ${({ isFocused, heightWhenOpen }) =>
    isFocused ? heightWhenOpen ?? '200px' : '0px'};
  transition: height 0.6s;
  transform-origin: top;
  /* transform: ${window.screen.availHeight + 170 > window.screen.availHeight
    ? 'translate(0%, -120%)'
    : 'none'}; */
  transform: ${window.screen.availHeight + 170 > window.screen.availHeight
    ? 'translate(0%, -120%)'
    : 'none'};
  overflow-y: hidden;
  top: 65px;
  background: #fff;
  border-radius: 0 0 0 5px;
  box-shadow: 0px 1px 7px 0px rgba(201, 201, 201, 0.5);

  > div {
    height: 100%;
    overflow-y: scroll;
    cursor: pointer;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #ddd;
      margin-top: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: #e4003a;
      border: 3px solid #e4003a;
    }
  }

  .autocomplete-option {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    :hover {
      background: #f1f1f1;
    }
  }
  .optionDisabled {
    padding: 10px 10px;
    pointer-events: none;
    :hover {
      background: #f1f1f1;
    }
  }
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  width: -webkit-fill-available;

  /* margin-bottom: 22px; */
  color: #4a4b4a;
  font-size: 1rem !important;
  pointer-events: ${({ disabled }) => disabled && 'none'};

  .inputContainer {
    border-radius: 14px;
    align-items: center;
    gap: 8px;
  }
  .chips {
    display: flex;
    gap: 8px;
    padding: 8px;
    flex-wrap: wrap;
    & span {
      border: 1px solid #cfcfcf;
      padding: 8px;
      border-radius: 14px;
    }
  }
  label {
    font-size: 12px;
    color: #757575;
    /* margin-bottom: 10px; */
    ${(props) =>
      props.isFocused &&
      css`
        color: #e71037;
      `}
  }
  input {
    border: 0;
    border-bottom: 1px solid #afafaf;

    width: calc(95% - 50px);
    font-size: 16px;
    color: #4a4b4a;
    padding: 8px;
    margin: 4px auto;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #afafaf;
    }
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: -15px;
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
`;
