import { grayscale } from 'polished';
import styled from 'styled-components';

export const Paper = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  width: fit-content;
`;
export const SubTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  width: fit-content;
  margin: 8px 0px;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  font-weight: 500;
  font-size: 18px;
  p {
    font-weight: 600;
    font-size: 24px;
  }
`;
export const RadioOptionContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) =>
    props.disabled
      ? '0 10px 26px rgb(0 0 0 / 5%)'
      : '0 10px 26px rgb(0 0 0 / 15%)'};
  padding: 1rem;
  border-radius: 1rem;
  gap: 2rem;
  width: 100%;
  filter: ${(props) => props.disabled && 'grayscale(1)'};
  pointer-events: ${(props) => props.disabled && 'none'};
  transition: all 300ms ease-in;
  flex: ${(props) => (props.disabled ? 0 : 1)};
  input[type='radio'] {
    pointer-events: all;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 500;
    color: #2f2a2a;
    display: ${(props) => (props.disabled ? 'none' : 'block')};
  }
  .selectContainer {
    display: ${(props) => (props.disabled ? 'none' : 'flex')};
  }
  .radioReplicar {
    display: ${(props) => (props.disabled ? 'none' : 'flex')};
  }
`;
export const BoxItem = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  width: fit-content;

  p {
    font-weight: 600;
    font-size: 24px;
  }
`;

export const Chips = styled.div`
  border-radius: 16px;
  background: white;
  border: 1px solid #d7d7d7;
  padding: 8px;
  width: fit-content;
  div {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;
