import React from 'react';
import { useHistory } from 'react-router-dom';
import * as St from '../../AnticipationConference/styles';
import { cpfCnpjMask, creditCardMask, currencyMask } from '~/utils/masks';
import { IHeaderConference } from '../../model';
import ContainerFlex from '~/components/containerFlex';
import Button from '~/components/button';

const HeaderConference: React.FC<IHeaderConference> = ({
  nomeCompleto,
  cpf,
  numeroCartao,
  valorTotal,
  data,
}) => {
  const history = useHistory<any>();
  return (
    <St.BoxCard>
      <ContainerFlex row space>
        <ContainerFlex marginRight={16} gap="15" marginTop={5}>
          <St.Title>Nome do cliente</St.Title>
          <St.Info>{nomeCompleto}</St.Info>
        </ContainerFlex>
        <ContainerFlex marginRight={16} gap="15" marginTop={5}>
          <span>CPF</span>
          <span>{cpfCnpjMask(cpf).masked}</span>
        </ContainerFlex>
        <ContainerFlex marginRight={16} gap="15" marginTop={5}>
          <span>Número do cartão</span>
          <span>{creditCardMask(numeroCartao).masked}</span>
        </ContainerFlex>
        <ContainerFlex marginRight={16} gap="15" marginTop={5}>
          <span>Valor total</span>
          <span>{`R$ ${currencyMask(valorTotal)}`}</span>
        </ContainerFlex>

        <ContainerFlex padding={16}>
          <Button
            fitContent
            onClick={() => {
              history.push('/invoice/antecipation/purchase', {
                dataPurchase: data,
              });
            }}
          >
            <span>Voltar</span>
          </Button>
        </ContainerFlex>
      </ContainerFlex>
    </St.BoxCard>
  );
};

export default HeaderConference;
