/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaUpload } from 'react-icons/fa';
import Collapse from '~/components/collapse';
import Container from '~/components/container';
import ClientData from '../../Components/ClientData';
import * as St from '../../Services/styles';
import CardTableList from '../../Components/CardTableList';
import { headerCardDecision } from '../../Headers/cardHeaders';
import { SharedCardServicesContext } from '../Services/sharedCardServiceContext';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import SharedServiceContext from '../../Services/sharedServiceContext';
import Input from '../../Components/input';
import TextArea from '~/components/textarea';
import Constants from '~/utils/constants';
import { LeadContext } from '~/contexts/leads';

export const UpgradeDecision = () => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const {
    cpfGlobal,
    setCpfGlobal,
    cardInfos,
    availableProducts,
    getInfoCards,
    getClientInformation,
    getPhoto,
    getAvailableProducts,
    setOpinion,
    setEventId,
    setProduct,
    setEvent,
    loadingGlobal,
    opinion,
    getProfiles,
    fileName,
    setFileName,
    setFile,
    file,
    disabledButton,
    product,
    event,
    executeEvent,
    executeEventNew,
    resetContext,
    handleAtribuir,
    handleDesatribuir,
  } = useContext(SharedServiceContext);
  const {
    cpf,
    setCpf,
    getNotClientCards,
    headerInfoArray,
    getEventsDetail,
    resetContextCard,
  } = useContext(SharedCardServicesContext);
  const { leads } = useContext(LeadContext);
  const { leadDetail } = leads;
  const [disabledUpgrade, setDisabledUpgrade] = useState(false);
  const [typeButton, setTypeButton] = useState<string>('');
  const inputFileRef = useRef(null);
  console.log('headerInfoArray UPGRADE DECISIOON', headerInfoArray);
  useEffect(() => {
    return () => {
      resetContext();
      resetContextCard();
    };
  }, []);

  useEffect(() => {
    if (
      opinion?.length <= 0 ||
      loadingGlobal.postExecuteEvent.loading ||
      !file ||
      !fileName ||
      disabledButton
    ) {
      setDisabledUpgrade(true);
    } else {
      setDisabledUpgrade(false);
    }
  }, [
    opinion,
    loadingGlobal.postExecuteEvent.loading,
    disabledButton,
    file,
    fileName,
  ]);
  const [typeDistribution, setTypeDistribution] = useState<
    'AUTOMATICA' | 'MANUAL'
  >('');
  useEffect(() => {
    if (state) {
      setCpfGlobal(state?.item?.cpf);
      setCpf(state?.item?.cpf);
      setEventId(state?.item?.id);
      setProduct({ id: 'CARTAO', value: state?.item?.produto?.value });
      setEvent(state?.item?.tipoEvento);
      setTypeDistribution(state?.item?.tipoDistribuicao);
    }
  }, [state]);

  useEffect(() => {
    if (cpfGlobal && cpf) {
      getClientInformation();
      getAvailableProducts();
      getInfoCards();
      getPhoto();
      getNotClientCards();
      getEventsDetail();
    }
  }, [cpfGlobal, cpf]);

  const toFoward = () => {
    getProfiles();
  };

  useEffect(() => {
    if (typeButton === 'REJECT') {
      setTypeButton('');
      // executeEvent(false, true);
      executeEventNew({
        hasFile: true,
        websocket: false,
        recusar: true,
      });
    }
  }, [typeButton]);

  useEffect(() => {
    if (typeButton === 'ACCEPT') {
      setTypeButton('');
      executeEventNew({
        hasFile: true,
        websocket: true,
        recusar: false,
      });
    }
  }, [typeButton]);

  return (
    <Container>
      <St.BoxCard>
        <Collapse collapseHeight={70} initialState="open" title="1. Cliente">
          <ClientData
            solicitation
            cardInfos={cardInfos}
            availableProducts={availableProducts}
            eventStart
            product={product}
            event={event}
            typeFlux=""
          />
        </Collapse>
        <Collapse title="2. Detalhes da solicitação de Upgrade" typeEvent>
          <CardTableList
            customerHeader={headerCardDecision}
            item={[
              headerInfoArray.planoAtual,
              headerInfoArray.novoPlano,
              headerInfoArray.novoTema,
              headerInfoArray.anuidade,
              headerInfoArray.celularZeuss,
            ]}
            type="viewable"
          />
          <ContainerFlex row space gap="20">
            <ContainerFlex>
              <Input
                label="Solicitante"
                name="solicitante"
                value={headerInfoArray.solicitadorVenda?.nome}
              />
              <Input
                label="Tipo de Produto"
                name="produto"
                value={headerInfoArray.tipoProduto}
              />
            </ContainerFlex>
            <ContainerFlex>
              <ContainerFlex row gap="10">
                <Input
                  label="Data solicitação"
                  name="dtSolicitacao"
                  value={new Date(
                    headerInfoArray.dataInclusao,
                  ).toLocaleDateString('pt-br')}
                />
                <Input
                  label="Hora solicitação"
                  name="hrSolicitacao"
                  value={new Date(
                    headerInfoArray.dataInclusao,
                  ).toLocaleTimeString('pt-br')}
                />
              </ContainerFlex>
              <ContainerFlex>
                <Input
                  label="Tipo de Evento"
                  name="tipoEvento"
                  value={headerInfoArray.tipoEvento}
                />
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
        </Collapse>
        <Collapse collapseHeight={50} title="3. Decisão">
          <ContainerFlex padding={20}>
            <TextArea
              placeholder="Digite o parecer..."
              style={{ fontStyle: 'italic' }}
              rows={10}
              value={opinion}
              onChange={(e) => setOpinion(e.target.value)}
            />
          </ContainerFlex>
        </Collapse>
        <ContainerFlex marginTop={20} row space>
          <ContainerFlex row center gap="20">
            <span style={{ fontWeight: 600, fontSize: '18px' }}>
              Gravação da venda
            </span>
            <input
              ref={inputFileRef}
              style={{ display: 'none' }}
              type="file"
              accept="audio/wav, audio/wave, audio/x-wav"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
            <Button size="small" onClick={() => inputFileRef.current.click()}>
              <FaUpload size={16} color="white" />
            </Button>
          </ContainerFlex>
          <ContainerFlex row>
            <Input
              label="Nome do arquivo"
              name="nomeArquivo"
              onChange={(e) => setFileName(e.target.value)}
            />
          </ContainerFlex>
        </ContainerFlex>
        <ContainerFlex>
          <span style={{ fontWeight: 500, fontSize: '14px' }}>
            {file?.name ?? ''}
          </span>
        </ContainerFlex>
        {typeDistribution === 'MANUAL' ? (
          !headerInfoArray?.responsavel?.idOperador ? (
            <ContainerFlex row gap="10" end marginTop={20}>
              <Button
                outlined
                onClick={() => {
                  history.goBack();
                }}
              >
                Voltar
              </Button>
              <Button
                disabled={
                  headerInfoArray?.solicitadorVenda?.idOperador ===
                  localStorage.getItem(Constants.USER_ID)
                }
                loading={loadingGlobal.atribuir.loading}
                onClick={async () => {
                  await handleAtribuir();
                  getEventsDetail();
                }}
              >
                Atribuir
              </Button>
            </ContainerFlex>
          ) : (
            <ContainerFlex row gap="10" end marginTop={20}>
              <Button
                outlined
                onClick={() => {
                  history.goBack();
                }}
              >
                Voltar
              </Button>
              {headerInfoArray?.responsavel?.idOperador && (
                <Button
                  outlined
                  disabled={
                    headerInfoArray?.responsavel?.idOperador !==
                      localStorage.getItem(Constants.USER_ID) ||
                    loadingGlobal.desatribuir.loading
                  }
                  loading={loadingGlobal.desatribuir.loading}
                  onClick={async () => {
                    await handleDesatribuir();
                    getEventsDetail();
                  }}
                >
                  Desatribuir
                </Button>
              )}
              <Button
                outlined
                disabled={
                  disabledButton || loadingGlobal.postForwardLead.loading
                }
                loading={loadingGlobal.postForwardLead.loading}
                onClick={() => {
                  toFoward();
                }}
              >
                Encaminhar
              </Button>
              <Button
                outlined
                disabled={disabledUpgrade}
                loading={loadingGlobal.getContactHistory.loading}
                onClick={() => {
                  setTypeButton('REJECT');
                }}
              >
                Recusar
              </Button>

              <Button
                disabled={disabledUpgrade}
                loading={loadingGlobal.postExecuteEvent.loading}
                onClick={() => setTypeButton('ACCEPT')}
              >
                Aprovar Upgrade
              </Button>
            </ContainerFlex>
          )
        ) : null}

        {typeDistribution === 'AUTOMATICA' && (
          <ContainerFlex row gap="10" end marginTop={20}>
            <Button
              outlined
              onClick={() => {
                history.goBack();
              }}
            >
              Voltar
            </Button>
            <Button
              outlined
              disabled={disabledButton || loadingGlobal.postForwardLead.loading}
              loading={loadingGlobal.postForwardLead.loading}
              onClick={() => {
                toFoward();
              }}
            >
              Encaminhar
            </Button>
            <Button
              outlined
              disabled={disabledUpgrade}
              loading={loadingGlobal.getContactHistory.loading}
              onClick={() => {
                setTypeButton('REJECT');
              }}
            >
              Recusar
            </Button>

            <Button
              disabled={disabledUpgrade}
              loading={loadingGlobal.postExecuteEvent.loading}
              onClick={() => setTypeButton('ACCEPT')}
            >
              Aprovar Upgrade
            </Button>
          </ContainerFlex>
        )}
      </St.BoxCard>
    </Container>
  );
};
