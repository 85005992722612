/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  AiOutlineCheck,
  AiOutlineSearch,
  AiOutlineClose,
} from 'react-icons/ai';
import Button from '~/components/button';
import Container from '~/components/container';
import Select from './Components/select';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from './Components/input';
import Loading from '~/components/loading';
import TableList from './Components/tableList';

import useApi, { ApiMethod } from '~/hooks/useApi';

import ConsultUserSearchResponse from '~/services/consult/user/search/response';
import Endpoints from '~/services/endpoints';
import { components } from '~/assets/index';

import * as St from '../styles';
import Dialog from './Components/dialog';
import { cpfMask, cpfUnmask, emailMask, phoneMask } from '~/utils/masks';
import CheckBox from '~/components/checkbox';
import Constants from '~/utils/constants';
import { LeadContext } from '~/contexts/leads';
import Pagination from '~/components/pagination';
import { removeAccents } from '~/utils/form';
import { matchEvents, matchProducts } from '~/utils/supports';
import {
  IAvailableProducts,
  IForm,
  LeadHistoric,
  LeadsListRoot,
  Operator,
} from './Interfaces/sharedInterfaces';
import { headerGeneralEvents, headerHistoric } from './Headers/globalHeaders';
import { UFs, eventType, status } from './utils/supports';

const EventBox = (): JSX.Element => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [form, setForm] = useState<IForm>({
    cpf: '',
    numeroCartao: '',
    nome: '',
    eventType: { id: 'TODOS', value: 'Todos' },
    productType: { id: 'TODOS', value: 'Todos' },
    status: 'TODOS',
    retProfile: '',
  });
  console.log(form);
  const formCLientInitialState = {
    nome: '',
    email: emailMask(''),
    uf: '',
    cpf: cpfMask(''),
    telefone: phoneMask(''),
    produto: { id: '', value: '' },
  };
  const [formClient, setFormClient] = useState(formCLientInitialState);
  const { addLeadData, clearLeadContext } = useContext(LeadContext);
  const [item, setItem] = useState<any>({});
  const [availabreProductType, setAvailabreProductType] = useState<
    IAvailableProducts[]
  >();
  const [idSelectCancel, setIdSelectCancel] = useState('');
  const [openModalDesistir, setOpenModalDesistir] = useState(false);
  const [msgModalDesistir, setMsgModalDesistir] = useState('');
  const [openModalDesistirFeed, setOpenModalDesistirFeed] = useState(false);
  const [msgModalDesistirFeed, setMsgModalDesistirFeed] = useState('');

  const api = useApi<ConsultUserSearchResponse>(
    Endpoints.events.getEvent,
    ApiMethod.GET,
    {},
    true,
  );
  const getEvents = useApi<LeadsListRoot>(
    Endpoints.events.getEventList,
    ApiMethod.POST,
    {},
  );
  const getRetProfiles = useApi<Operator[]>(
    Endpoints.events.getRetProfiles,
    ApiMethod.GET,
  );
  const getEventHistory = useApi<LeadHistoric[]>(
    Endpoints.events.getLeadHistoric,
    ApiMethod.GET,
    {},
    true,
  );

  const getAvailableProductTypes = useApi(
    Endpoints.events.getAvailableProductTypes,
    ApiMethod.GET,
  );

  const getAccountInfoEventCancel = useApi<any>(
    `${Endpoints.useCases.getAccountInfoEventCancel.replace(
      'PARAM_ID',
      idSelectCancel,
    )}`,
    ApiMethod.GET,
  );

  const postDesistirCancelamento = useApi<any>(
    `${Endpoints.events.postDesistirCancelamento.replace(
      'PARAM_ID',
      idSelectCancel,
    )}`,
    ApiMethod.POST,
  );

  useEffect(() => {
    if (!idSelectCancel) return;
    (async () => {
      try {
        const response = await getAccountInfoEventCancel.callApi();
        const { data } = response as any;
        setMsgModalDesistir(data?.mensagem);
        setOpenModalDesistir(true);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [idSelectCancel]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAvailableProductTypes.callApi();
        const { data } = response as any;
        setAvailabreProductType(data);
        setAvailabreProductType((prev) => [
          ...prev,
          { id: 'TODOS', nome: 'Todos', tipoProduto: 'TODOS' },
        ]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    (async () => {
      await getRetProfiles.callApi();
    })();
  }, []);

  useEffect(() => {
    clearLeadContext();
  }, []);

  const [colorFilter, setColorFilter] = useState([
    'AMARELO',
    'VERDE',
    'VERMELHO',
  ]);
  const isAdmin = JSON.parse(
    localStorage.getItem(Constants.USER_ROLE),
  )?.includes('ADM');

  const [dataHistoric, setDataHistoric] = useState([]);
  const [modalDescriptionOpen, setModalDescriptionOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleGetLeadHistoric = async (id) => {
    const response = await getEventHistory.callApi({ idEvento: id });
    if (response.status === 200) {
      const newList = [];

      response.data?.forEach((row) => {
        const item = [
          {
            border: true,
            value: row?.dataAcao.split('T')[0]?.split('-').reverse().join('/'),
            maxChar: 30,
            textAlign: 'center',
          },
          {
            border: true,
            value: row?.descricao,
            maxChar: 40,
            textAlign: 'left',
            render: () => {
              return (
                <St.DescContainer
                  onClick={() => {
                    setModalDescriptionOpen(true);
                    setModalMessage(row?.descricao);
                  }}
                >
                  <components.Warning fontSize={12} />
                </St.DescContainer>
              );
            },
          },
          {
            border: true,
            value: row?.operador?.nome,
            maxChar: 10,
            textAlign: 'center',
          },
          {
            border: true,
            value: row?.status,
            maxChar: 20,
            textAlign: 'center',
          },
        ];
        newList.push(item);
      });
      setDataHistoric(newList);
      setModalOpen('historic');
    }
  };

  const handleSearchAction = async (item) => {
    const responseEvent = await matchEvents(eventType, item?.tipoEvento);
    const responseProduct = await matchProducts(
      availabreProductType,
      item?.produto,
    );
    const idOperador = localStorage.getItem(Constants.USER_ID);

    if (
      (item?.tipoDistribuicao === 'AUTOMATICA' &&
        item.responsavel?.idOperador !== idOperador) ||
      (item.tipoDistribuicao === 'MANUAL' &&
        item.responsavel &&
        item.responsavel?.idOperador !== idOperador) ||
      item.status === 'SUCESSO' ||
      item.status === 'INSUCESSO'
    ) {
      handleGetLeadHistoric(item.id);
      return;
    }
    item.tipoEvento = { id: responseEvent?.id, value: responseEvent?.value };
    item.produto = {
      id: responseProduct?.id,
      value: responseProduct?.nome,
    };
    addLeadData('leadDetail', { ...item });
    if (item.status === 'PENDENTE') {
      goToSolicitationDetail(item);
    } else {
      goToDecisionDetail(item);
    }
  };

  const [listMyLeads, setListMyLeads] = useState(false);

  const searchEvents = useCallback(
    async (page?, myEvents?) => {
      const operator = JSON.parse(localStorage.getItem('@NovaEra:user'));
      const usuario = form?.retProfile
        ? getRetProfiles.data.find((e) => e.idOperador === form.retProfile)
        : {
            idOperador: localStorage.getItem(Constants.USER_ID),
            nome: operator?.nomeTitular,
          };
      const payload = {
        limitePorPagina: 10,
        pagina: page ?? currentPage,
        produto: removeAccents(form.productType.value)?.toUpperCase(),
        usuario,
        sinalizadores: colorFilter,
        status: form.status,
        tipoEvento: form.eventType.id,
        caixaEventoResponsavel: listMyLeads ?? myEvents ?? false,
      };

      const response = await getEvents.callApi(payload);

      if (response.status === 200) {
        const newList = [];
        response.data?.pagina.forEach((row) => {
          const item = [
            {
              type: 'color',
              maxChar: 5,
              color:
                row?.sinalizador === 'VERDE'
                  ? '#1EAB35'
                  : row?.sinalizador === 'AMARELO'
                  ? '#F4D151'
                  : '#E71037',
              icon:
                row.status === 'SUCESSO' ? (
                  <AiOutlineCheck style={{ color: 'green' }} />
                ) : row.status === 'INSUCESSO' ? (
                  <AiOutlineClose style={{ color: 'red' }} />
                ) : null,
            },
            {
              border: true,
              value: row?.nome,
              maxChar: 30,
              textAlign: 'left',
            },
            {
              border: true,
              value: row?.tipoEvento,
              maxChar: 10,
              textAlign: 'center',
            },
            {
              border: true,
              value: row?.produto,
              maxChar: 10,
              textAlign: 'center',
            },
            {
              border: true,
              value: row?.dataCriacao
                ?.split('T')[0]
                ?.split('-')
                .reverse()
                .join('/'),
              maxChar: 20,
              textAlign: 'center',
            },
            {
              border: true,
              value: row?.dataLimiteEvento
                ?.split('T')[0]
                .split('-')
                .reverse()
                .join('/'),
              maxChar: 20,
              textAlign: 'center',
            },
            {
              border: true,
              value: row?.status,
              maxChar: 15,
              textAlign: 'center',
            },
            {
              border: true,
              value: row?.origem,
              maxChar: 15,
              textAlign: 'center',
            },
            {
              border: true,
              item: row,
              maxChar: 30,
              textAlign: 'center',
              searchAction: async (id, item) => {
                setItem(item);
              },
            },
          ];
          newList.push(item);
        });
        setList(newList);
      }
    },
    [form, colorFilter, currentPage, listMyLeads],
  );

  useEffect(() => {
    searchEvents(1, false);
  }, []);
  useEffect(() => {
    if (currentPage) {
      searchEvents();
    }
  }, [currentPage]);
  useEffect(() => {
    if (listMyLeads) {
      searchEvents(1, true);
    }
  }, [listMyLeads]);

  const goToSolicitationDetail = (item?: ConsultUserSearchResponse) => {
    if (
      item.produto.value === 'Cartão' &&
      item.tipoEvento.value === 'Upgrade'
    ) {
      history.push(
        '/sales/eventCenter/SolicitationDetails',
        { item, typeFlux: 'SOLICITATION' } ?? null,
      );
    }
    if (
      item.produto.value === 'Conta' &&
      item.tipoEvento.value === 'Cancelamento'
    ) {
      history.push(
        '/sales/eventCenter/SolicitationDetails',
        { item, typeFlux: 'SOLICITATION' } ?? null,
      );
    }
    if (
      item.produto.value === 'Cartão' &&
      item.tipoEvento.value === 'Downgrade'
    ) {
      history.push(
        '/sales/eventCenter/downgradeDecision',
        { item, typeFlux: 'SOLICITATION' } ?? null,
      );
    }
    if (item.produto.value === 'Benefício') {
      history.push('/sales/eventCenter/leadSolicitation', {
        item,
      });
    }
  };

  const goToDecisionDetail = (item?: ConsultUserSearchResponse) => {
    console.log('Estou aqui com a acao do btn (goToDecisionDetail)', item);
    setIdSelectCancel('');
    if (item.produto.value === 'Cartão') {
      history.push('/sales/eventCenter/upgradeDecision', {
        item,
      });
    }
    if (item.produto.value === 'Benefício') {
      history.push('/sales/eventCenter/leadDecision', {
        item,
      });
    }
    if (item.produto.value === 'Conta') {
      // mostra modal com as açoes!!!!
      history.push(
        '/sales/eventCenter/accountLead/details',
        { item, typeFlux: 'SOLICITATION' } ?? null,
      );
      // setIdSelectCancel(item?.id);
    }
  };

  const FilterButton = ({
    filterColor,
  }: {
    filterColor: 'VERDE' | 'AMARELO' | 'VERMELHO';
  }) => {
    const color = { VERMELHO: '#E71037', AMARELO: '#F4D151', VERDE: '#1EAB35' }[
      filterColor
    ];
    return (
      <div
        style={{ height: 15, width: 15, cursor: 'pointer' }}
        onClick={() =>
          setColorFilter((prev) => {
            if (prev.includes(filterColor)) {
              return prev.filter((c) => c !== filterColor);
            }
            return [...prev, filterColor];
          })
        }
      >
        <div
          style={{
            width: 10,
            height: 10,
            outline: `1px solid ${color}`,
            outlineOffset: '3px',
            borderRadius: '100%',
            backgroundColor: colorFilter.includes(filterColor) && color,
          }}
        />
      </div>
    );
  };

  const [modalOpen, setModalOpen] = useState('');

  const handleCreateClient = () => {
    setModalOpen('newClient');
  };

  const handleDesistirCancelamento = async () => {
    // Realiza a desistencia do cancelamento!!!
    const operator = JSON.parse(localStorage.getItem('@NovaEra:user'));
    const payload = {
      operador: {
        idOperador: localStorage.getItem(Constants.USER_ID),
        nome: operator?.nomeTitular,
      },
    };

    try {
      const response = await postDesistirCancelamento.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        setMsgModalDesistirFeed('Tua solicitaçao foi concluida com sucesso');
      } else {
        console.log('Passei no else - ', response?.data);
        setMsgModalDesistirFeed(response?.data);
      }
    } catch (err) {
      setMsgModalDesistirFeed(err.message);
      console.log(err.message);
      console.error(err);
    } finally {
      setIdSelectCancel('');
      setOpenModalDesistirFeed(true);
      searchEvents();
    }
  };

  const loading =
    api.loading ||
    getEvents.loading ||
    getRetProfiles.loading ||
    postDesistirCancelamento.loading ||
    getAccountInfoEventCancel.loading;

  return (
    <>
      <Loading status={loading} />

      <Container>
        <Dialog widthInPercent={90} open={modalOpen === 'historic'}>
          <ContainerFlex center padding={10}>
            <St.DialogContent>
              <Dialog widthInPercent={80} open={modalDescriptionOpen}>
                <ContainerFlex center end padding={10}>
                  <St.DialogContent>
                    <St.Title style={{ textAlign: 'center' }}>
                      Descrição
                    </St.Title>
                    <St.Title
                      style={{
                        textAlign: 'start',
                        fontSize: 18,
                        fontWeight: 500,
                      }}
                    >
                      {modalMessage}
                    </St.Title>
                    <ContainerFlex end row marginTop={60}>
                      <Button
                        onClick={() => {
                          setModalDescriptionOpen(false);
                        }}
                      >
                        Fechar
                      </Button>
                    </ContainerFlex>
                  </St.DialogContent>
                </ContainerFlex>
              </Dialog>
              <St.Title style={{ textAlign: 'center' }}>
                Detalhes do{' '}
                {typeof item?.tipoEvento === 'string' &&
                  item?.tipoEvento?.charAt(0)?.toUpperCase() +
                    item?.tipoEvento?.slice(1)?.toLowerCase()}
              </St.Title>
              <TableList list={dataHistoric} customerHeader={headerHistoric} />
              <ContainerFlex row gap="10" end marginTop={10}>
                <Button outlined onClick={() => setModalOpen('')}>
                  Voltar
                </Button>
              </ContainerFlex>
            </St.DialogContent>
          </ContainerFlex>
        </Dialog>
        <Dialog widthInPercent={40} open={modalOpen === 'myEventsWarning'}>
          <ContainerFlex center padding={10}>
            <St.DialogContent>
              <ContainerFlex center end padding={10}>
                <St.Title style={{ textAlign: 'center' }}>Detalhes</St.Title>
                <ContainerFlex row>
                  <span>
                    {' '}
                    - <b>Marcado</b>: Listará todos os eventos que foram criados
                    por mim, independente do responsável atual.
                  </span>
                </ContainerFlex>
                <ContainerFlex row marginTop={20}>
                  <span>
                    {' '}
                    - <b>Desmarcado</b>: Listará todos os eventos que sou o
                    responsável atual e que precisam de alguma ação
                  </span>
                </ContainerFlex>
                <ContainerFlex end row marginTop={60}>
                  <Button
                    onClick={() => {
                      setModalOpen('');
                    }}
                  >
                    Fechar
                  </Button>
                </ContainerFlex>
              </ContainerFlex>
            </St.DialogContent>
          </ContainerFlex>
        </Dialog>
        <Dialog widthInPercent={40} open={openModalDesistir}>
          <ContainerFlex center end padding={10}>
            <St.DialogContent>
              <St.Title style={{ textAlign: 'center' }}>Desistir</St.Title>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {msgModalDesistir}
              </span>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '5%',
                }}
              >
                Deseja desistir do registro de cancelamento ?
              </span>
              <ContainerFlex center horizontal row marginTop={60}>
                <Button
                  onClick={() => {
                    setOpenModalDesistir(false);
                  }}
                  style={{ marginRight: '5%' }}
                  outlined
                >
                  Voltar
                </Button>
                <Button
                  onClick={() => {
                    setOpenModalDesistir(false);
                    handleDesistirCancelamento();
                  }}
                >
                  Desistir
                </Button>
              </ContainerFlex>
            </St.DialogContent>
          </ContainerFlex>
        </Dialog>
        <Dialog widthInPercent={30} open={openModalDesistirFeed}>
          <ContainerFlex center end padding={10}>
            <St.DialogContent>
              <St.Title style={{ textAlign: 'center' }}>Desistir</St.Title>
              <St.Title
                style={{
                  textAlign: 'start',
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                {msgModalDesistirFeed}
              </St.Title>
              <ContainerFlex end row marginTop={60}>
                <Button
                  onClick={() => {
                    setOpenModalDesistirFeed(false);
                  }}
                >
                  Fechar
                </Button>
              </ContainerFlex>
            </St.DialogContent>
          </ContainerFlex>
        </Dialog>

        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                name="cpf"
                value={form.cpf}
                onChange={(e) =>
                  setForm((prev) => ({ ...prev, cpf: e.target.value.masked }))
                }
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Input
                label="Nome"
                name="Nome"
                value={form.nome}
                onChange={(e) => setForm({ ...form, nome: e.target.value })}
              />
            </ContainerFlex>
            <ContainerFlex
              gap="20"
              space
              end
              style={{ padding: '0px 20px' }}
              flex={1.5}
            >
              <Button
                fitContent
                onClick={() => {
                  history.push('/sales/eventCenter/requestEvent', {
                    cpf: cpfUnmask(form?.cpf),
                    nome: form?.nome,
                    typeFlux: 'STANDARD',
                  });
                }}
                disabled={!(cpfUnmask(form?.cpf)?.length === 11) && !form?.nome}
              >
                <span>Iniciar</span>
              </Button>

              <p
                style={{ pointerEvents: 'none' }}
                onClick={() => handleCreateClient()}
              >
                Ainda não é cliente?{' '}
                <b style={{ color: '#E71037', cursor: 'pointer' }}>
                  Clique aqui
                </b>
              </p>
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex
            gap="20"
            space
            style={{ padding: '0px 20px' }}
            flex={1.5}
          >
            {errorMessage && <p style={{ color: '#E71037' }}>{errorMessage}</p>}
          </ContainerFlex>
        </St.BoxCard>
        <St.BoxCard>
          <ContainerFlex
            style={{ borderBottom: '1px solid #d3d3d3' }}
            row
            space
          >
            <ContainerFlex center row style={{ flex: 1 }}>
              <ContainerFlex width={10} maxWidth={1}>
                <CheckBox
                  onChangeStatus={(value) => {
                    setListMyLeads(value);
                    setForm((prev) => ({ ...prev, retProfile: '' }));
                  }}
                  value={listMyLeads}
                />
              </ContainerFlex>
              <St.TitleCategory style={{ fontWeight: 'bold' }}>
                Listar todos os eventos que criei
                {/* Minha caixa de eventos -{' '}
                {form?.retProfile &&
                  getRetProfiles?.data?.find(
                    (e) => e.idOperador === form.retProfile,
                  )?.nome} */}
              </St.TitleCategory>
              <St.DescContainer
                onClick={() => {
                  setModalOpen('myEventsWarning');
                }}
              >
                <components.Warning fontSize={12} />
              </St.DescContainer>
            </ContainerFlex>
            {isAdmin && (
              <ContainerFlex end maxWidth={30} width={30}>
                <Select
                  label="Selecionar responsável"
                  onChange={(e) => {
                    setForm((prev) => ({
                      ...prev,
                      retProfile: e,
                    }));
                  }}
                  value={form.retProfile}
                  options={getRetProfiles?.data?.map((e) => ({
                    value: e.nome,
                    id: e.idOperador,
                  }))}
                />
              </ContainerFlex>
            )}
          </ContainerFlex>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Select
                label="Produto"
                onChange={(e) => {
                  setForm((prev) => ({ ...prev, productType: e }));
                }}
                value={form.productType.value}
                options={availabreProductType?.map((e) => ({
                  value: e.nome,
                  id: e.id,
                }))}
                typeSelect="EVENTS_PRODUCT"
              />
            </ContainerFlex>
            <ContainerFlex padding={16}>
              <Select
                label="Tipo de Evento"
                onChange={(e) => setForm((prev) => ({ ...prev, eventType: e }))}
                value={form.eventType.value}
                options={eventType}
                typeSelect="EVENTS_TYPE"
              />
            </ContainerFlex>
            <ContainerFlex padding={16}>
              <Select
                label="Status"
                onChange={(e) => setForm((prev) => ({ ...prev, status: e }))}
                value={form.status}
                options={status}
              />
            </ContainerFlex>
            <ContainerFlex
              row
              justifyContent="center"
              center
              style={{ maxWidth: '30px' }}
            >
              <AiOutlineSearch
                size={30}
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => {
                  searchEvents(1, false);
                }}
              />
            </ContainerFlex>

            <ContainerFlex style={{ padding: '30px 10px' }} center>
              <ContainerFlex
                style={{
                  borderRadius: '12px',
                  background: '#fff',
                  boxShadow: '0 10px 26px rgb(0 0 0 / 5%)',
                  padding: '5px 20px',
                  maxWidth: '70%',
                }}
                row
                space
                center
              >
                <FilterButton filterColor="VERMELHO" />
                <FilterButton filterColor="AMARELO" />
                <FilterButton filterColor="VERDE" />
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
          <ContainerFlex>
            <ContainerFlex justifyContent="start" padding={0} flex={1.5}>
              <Button
                fitContent
                onClick={() => searchEvents(1, true)}
                loading={api.loading}
                disabled={!form.eventType && !form.productType && !form.status}
              >
                <span>Meus eventos</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>

        {list.length <= 0 || api.status === 404 ? (
          <St.BoxCard>
            <ContainerFlex center>
              <St.TitleCategory>Cliente não encontrado</St.TitleCategory>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <St.BoxCard style={{ background: '#fafbfe' }}>
            <ContainerFlex row>
              {(api.status === 404 || list.length <= 0) && (
                <ContainerFlex center>
                  <St.TitleCategory>
                    {api.status === 404
                      ? api.data.mensagem
                      : 'Nenhum resultado encontrado'}
                  </St.TitleCategory>
                </ContainerFlex>
              )}
            </ContainerFlex>

            <ContainerFlex>
              {list.length > 0 && (
                <TableList
                  customerHeader={headerGeneralEvents}
                  list={list}
                  handleButtonClick={(e) => handleSearchAction(e.item)}
                />
              )}
              <Pagination
                totalPages={getEvents.data?.totalDePaginas}
                onCurrentPageChange={(e) => {
                  setCurrentPage(e);
                }}
              />
              <ContainerFlex center>
                {(api.status > 300 || list.length === 0) && (
                  <St.TitleCategory>
                    Não há resultados para mostrar aqui
                  </St.TitleCategory>
                )}
              </ContainerFlex>
            </ContainerFlex>
          </St.BoxCard>
        )}
        <Dialog widthInPercent={60} open={modalOpen === 'newClient'}>
          <ContainerFlex center padding={10}>
            <St.BoxCard>
              <St.Title style={{ textAlign: 'center' }}>
                Cadastro de Pessoa
              </St.Title>
              <ContainerFlex space row gap="20">
                <ContainerFlex>
                  <Input
                    label="Nome"
                    name="clientNname"
                    value={formClient.nome}
                    onChange={(e) =>
                      setFormClient((prev) => ({
                        ...prev,
                        nome: e.target.value,
                      }))
                    }
                  />
                  <Input
                    label="Email"
                    name="clientEmail"
                    value={formClient.email}
                    onChange={(e) =>
                      setFormClient((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />

                  <Select
                    label="UF"
                    options={UFs}
                    name="clientUf"
                    value={formClient.uf}
                    onChange={(e) =>
                      setFormClient((prev) => ({
                        ...prev,
                        uf: e,
                      }))
                    }
                  />
                </ContainerFlex>
                <ContainerFlex>
                  <Input
                    label="CPF"
                    name="clientCpf"
                    value={formClient.cpf?.masked}
                    mask={MaskTypes.CPF}
                    onChange={(e) =>
                      setFormClient((prev) => ({
                        ...prev,
                        cpf: e.target.value,
                      }))
                    }
                  />
                  <Input
                    label="Telefone"
                    name="clientPhone"
                    value={formClient.telefone?.masked}
                    mask={MaskTypes.PHONE}
                    onChange={(e) =>
                      setFormClient((prev) => ({
                        ...prev,
                        telefone: e.target.value,
                      }))
                    }
                  />
                  <Select
                    label="Produto"
                    options={[
                      { id: 'BENEFICIO', value: 'Beneficio' },
                      { id: 'CARTAO', value: 'Cartão' },
                    ]}
                    name="clientBenefit"
                    value={formClient.produto.value}
                    onChange={(e) => {
                      setFormClient((prev) => ({
                        ...prev,
                        produto: e,
                      }));
                    }}
                  />
                </ContainerFlex>
              </ContainerFlex>

              <ContainerFlex row gap="10" end marginTop={20}>
                <Button
                  outlined
                  onClick={() => {
                    setModalOpen('');
                    setFormClient(formCLientInitialState);
                  }}
                >
                  Voltar
                </Button>
                <Button onClick={() => console.log('poi')}>
                  Salvar e enviar
                </Button>
              </ContainerFlex>
            </St.BoxCard>
          </ContainerFlex>
        </Dialog>
      </Container>
    </>
  );
};

export default EventBox;
