/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormState } from '../models';

interface IFilterBiometricsContext {
  form: FormState;
  setForm: React.Dispatch<React.SetStateAction<FormState>>;
  list: any[];
  setList: React.Dispatch<React.SetStateAction<any[]>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const FilterBiometricsContext = createContext<IFilterBiometricsContext>(
  {} as IFilterBiometricsContext,
);

export const FilterBiometricProvider = ({ children }) => {
  const [list, setList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [prevLocation, setPrevLocation] = useState<string | null>(null);
  const [form, setForm] = useState<FormState>({
    cpf: { masked: '', unmasked: '' },
    origem: 'TODAS',
    resultLiveness: 'TODOS',
    baseResultFace: 'TODOS',
    dataBiometriaInicial: '',
    dataBiometriaFinal: '',
  });

  const location = useLocation();

  const resetContext = () => {
    setForm({} as FormState);
    setList([]);
    setCurrentPage(1);
  };

  useEffect(() => {
    const isConsultationRoute =
      location.pathname === '/consult/user/biometrics';
    const isDetailRoute =
      location.pathname === '/consult/user/biometrics/detail';

    if (prevLocation && !isConsultationRoute && !isDetailRoute) {
      resetContext();
    }
    setPrevLocation(location.pathname);
  }, [location, prevLocation]);

  useEffect(() => {
    if (!form.origem) {
      setForm((prev) => ({
        ...prev,
        origem: 'TODAS',
        resultLiveness: 'TODOS',
        baseResultFace: 'TODOS',
      }));
    }
  }, [form]);

  console.log(currentPage);

  return (
    <FilterBiometricsContext.Provider
      value={{
        form: {
          ...form,
          dataBiometriaFinal: form.dataBiometriaFinal
            ? form.dataBiometriaFinal
            : '',
          dataBiometriaInicial: form.dataBiometriaInicial
            ? form.dataBiometriaInicial
            : '',
        },
        setForm,
        list,
        setList,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </FilterBiometricsContext.Provider>
  );
};

export const useFilterBiometrics = () => useContext(FilterBiometricsContext);
