/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-destructuring */
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa';
import Container from '~/components/container';

import * as St from './styles';
import FilterBiometrics from './components/FilterBiometrics';
import ContainerFlex from '~/components/containerFlex';
import TableList from '~/components/tableList';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Pagination from '~/components/pagination';
import Button from '~/components/button';
import { useFilterBiometrics } from './context/FilterBiometricContext';

interface BiometricsResponse {
  currentPage: number;
  items: any[];
  totalPages: number;
  pageSize: number;
}

export interface BiometricsItem {
  codigoLiveness: string;
  codigoTransacional: string;
  dataCriacao: string;
  nomeCompleto: string;
  origemBiometria: string;
  ticket: string;
}

const ConsultFacialBiometrics = () => {
  const {
    form,
    setForm,
    list,
    setList,
    setCurrentPage,
    currentPage,
  } = useFilterBiometrics();
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();

  const customHeader = [
    {
      id: 1,
      value: 'Nome completo',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'Data validação',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Origem Biometria',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Prova de vida',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Base de faces',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 6,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const RequestBiometrics = useApi<BiometricsResponse>(
    Endpoints.integration.getFilterBiometrics,
    ApiMethod.GET,
    {},
    true,
  );

  const RequestCertifaceCSV = useApi(
    Endpoints.integration.getCertifaceExportCSV,
    ApiMethod.GET,
    {},
    true,
  );

  const getCertifaceReportCSVDownload = async () => {
    try {
      const response = await RequestCertifaceCSV.callApi({
        cpf: form.cpf?.unmasked,
        origem: form?.origem,
        start: form.dataBiometriaInicial
          ? moment(form.dataBiometriaInicial, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : '',
        end: form.dataBiometriaFinal
          ? moment(form.dataBiometriaFinal, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : '',
        id_faces: form.baseResultFace ? form.baseResultFace : '',
        id_liveness: form.resultLiveness ? form.resultLiveness : '',
      });

      if (response.status === 200) {
        const blob = new Blob([response.data as Blob], {
          type: 'text/csv;charset=UTF-8',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'relatorio_certiface.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBiometrics = async () => {
    const response = await RequestBiometrics.callApi({
      cpf: form.cpf?.unmasked,
      origem: form?.origem,
      size: pageSize,
      page: currentPage,
      start: form.dataBiometriaInicial
        ? moment(form.dataBiometriaInicial, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : '',
      end: form.dataBiometriaFinal
        ? moment(form.dataBiometriaFinal, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : '',
      id_faces: form.baseResultFace ? form.baseResultFace : '',
      id_liveness: form.resultLiveness ? form.resultLiveness : '',
    });

    if (response.status === 200) {
      const {
        items,
        currentPage,
        totalPages,
        pageSize,
      }: BiometricsResponse = response.data;
      setTotalPages(totalPages);
      setPageSize(pageSize);
      return items;
    }
    return null;
  };

  const OriginBiometrics = (origin) => {
    switch (origin) {
      case 'TODAS':
        return 'Todas';
      case 'T_SENHA':
        return 'Alteração de senha';
      case 'T_EMPRESTIMO':
        return 'Empréstimo Hotmoney';
      case 'T_CHANGE_PERSONAL_DATA':
        return 'Alteração de dados';
      case 'T_DEVICE_ID':
        return 'Device ID';
      case 'PROPOSTA':
        return 'Proposta';
      default:
        return '';
    }
  };

  const handleSubmit = (page = 1) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getBiometrics();

      if (data) {
        const newList = data.map((data) => {
          return [
            {
              border: true,
              title: 'Nome completo',
              value: data.nomeCompleto,
              maxChar: 20,
            },
            {
              title: 'Data de validação',
              value: moment(data.dataCriacao, 'YYYY-MM-DD').format(
                'DD/MM/YYYY',
              ),
              textAlign: 'center',
            },
            {
              title: 'Origem Biometria',
              value: OriginBiometrics(data.origemBiometria),
              textAlign: 'center',
            },
            {
              title: 'Prova de vida',
              value: data.codigoLiveness,
              textAlign: 'center',
            },
            {
              title: 'Base de faces',
              value: data.codigoTransacional,
              textAlign: 'center',
            },
            { item: data, searchAction: () => goToDetail(data.ticket) },
          ];
        });
        setList(newList);
      } else {
        setList([]);
      }

      setIsSubmitting(false);
    };

    if (isSubmitting) {
      fetchData();
    }
  }, [currentPage, isSubmitting]);

  const goToDetail = (id: string) => {
    history.push('/consult/user/biometrics/detail', {
      id,
    });
  };

  useEffect(() => {
    const cpfFilled = form.cpf?.unmasked && form.cpf.unmasked.length === 11;
    const datesFilled = form?.dataBiometriaInicial && form?.dataBiometriaFinal;

    if ((cpfFilled || (datesFilled && form.origem)) && !isSubmitting) {
      handleSubmit(currentPage);
    }
  }, [currentPage]);

  return (
    <>
      <Loading
        status={RequestBiometrics?.loading || RequestCertifaceCSV?.loading}
      />
      <Container>
        <FilterBiometrics
          onClick={() => {
            handleSubmit(1);
          }}
        />

        {list.length <= 0 ? (
          <St.BoxCard>
            <ContainerFlex center>
              <span>Dados biométricos não encontrados</span>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <St.BoxCard>
            <ContainerFlex flex="0.2">
              {RequestBiometrics.status === 200 && !list.length ? (
                <ContainerFlex center marginTop={30}>
                  <span>Dados biométricos não localizados</span>
                </ContainerFlex>
              ) : null}
              <ContainerFlex>
                {list.length > 0 && (
                  <>
                    <ContainerFlex end flex="0.2" marginBottom={30}>
                      <Button
                        fitContent
                        onClick={getCertifaceReportCSVDownload}
                      >
                        <FaDownload />
                        <span style={{ marginLeft: '8px' }}>Download</span>
                      </Button>
                    </ContainerFlex>
                    <ContainerFlex>
                      <TableList customerHeader={customHeader} list={list} />
                    </ContainerFlex>
                    <ContainerFlex marginTop={20} center flex={1}>
                      <Pagination
                        totalPages={totalPages}
                        initialCurrentPage={currentPage}
                        onCurrentPageChange={setCurrentPage}
                      />
                    </ContainerFlex>
                  </>
                )}
              </ContainerFlex>
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default ConsultFacialBiometrics;
