/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Loading from '~/components/loading';
import Container from '~/components/container';

import ListConference from '../components/listConference';
import HeaderConference from '../components/headerConference';

import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';

import * as St from './styles';
import { IAntecipationPurchase } from '../model';
import { currencyMask } from '~/utils/masks';
import { icons } from '~/assets';

const AntecipationConference: React.FC = () => {
  const history = useHistory<any>();
  const locationState = history.location.state;

  const [valorTotal, setValorTotal] = useState('');
  const [dialog, setDialog] = useState({ open: false, error: false });
  const [dialogMessage, setDialogMessage] = useState('');
  const [data, setData] = useState<IAntecipationPurchase[]>(null);

  const RequestCreateAntecipation = useApi(
    Endpoints.invoices.postAntecipationArray,
    ApiMethod.POST,
  );

  useEffect(() => {
    const value = [] as IAntecipationPurchase[];
    locationState?.dataPurchase.map((dataP: IAntecipationPurchase) => {
      if (dataP.parcelasAAntecipar > 0) {
        value.push(dataP);
      }
      return dataP;
    });
    setData(value);
  }, []);

  useEffect(() => {
    async function populaValor() {
      let aux = 0;
      if (data) {
        setValorTotal('');
        data.map((item) => {
          aux = item.valorAntecipacao + aux;
        });
        setValorTotal(String(aux));
      }
    }

    populaValor();
  }, [data]);

  const requestFinancialParcel = async () => {
    const payload = [] as any[];
    data.map((dataP: IAntecipationPurchase) => {
      payload.push({
        cartao: dataP?.cartao,
        cpf: dataP?.cpf,
        dataFechamento: String(moment(dataP?.dataCorte).format('YYYY-MM-DD')),
        email: dataP?.email,
        idParcelamento: dataP.idCompra,
        nomeCliente: dataP?.nomeCompleto,
        parcelasAntecipadas: dataP?.parcelasAAntecipar,
        valorAntecipacao: dataP?.valorAntecipacao,
        tipoAntecipacao: 'COMPRA',
      });
    });

    try {
      const response: any = await RequestCreateAntecipation.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        setDialog({ open: true, error: false });
        setDialogMessage('Solicitação enviada com sucesso!');
      } else {
        setDialog({ open: true, error: true });
        setDialogMessage(
          response.data?.mensagem || 'Tivemos um erro, Tente novamente!',
        );
      }
    } catch (err) {
      setDialog({ open: true, error: true });
      setDialogMessage('Tivemos um erro, Tente novamente!');
    }
  };

  return (
    <Container>
      <Loading status={RequestCreateAntecipation.loading} />
      <Dialog
        icon={dialog?.error ? icons.exclamation : icons.check}
        open={dialog?.open}
        positiveLabel="Entendi"
        positiveAction={() => {
          if (!dialog.error) {
            history.push('/invoice/antecipation/purchase');
          }
          setDialog({ open: false, error: false });
        }}
        message={dialogMessage}
      />
      {data?.length > 0 ? (
        <>
          <HeaderConference
            nomeCompleto={data[0]?.nomeCompleto}
            cpf={data[0]?.cpf}
            numeroCartao={data[0]?.cartao}
            valorTotal={Number(valorTotal)}
            data={locationState?.dataPurchase}
          />

          <St.BoxCard>
            <ListConference data={data} />

            <ContainerFlex row marginBottom={20}>
              <St.IconExclamation src={icons.exclamation} alt="Logo TeuCard" />
              <St.DescriptionInfo>
                {`Comunique o cliente que deverá pagar no caixa o quanto antes. Caso
            não seja realizado o pagamento, este valor será cobrado na próxima fatura
            que vence dia ${moment(data[0]?.dataVencimento).format(
              'DD/MM/YYYY',
            )}.
            Deseja antecipar o valor de R$ ${currencyMask(valorTotal)} Reais?`}
              </St.DescriptionInfo>
            </ContainerFlex>

            <ContainerFlex row end padding={15}>
              <Button
                fitContent
                onClick={() => {
                  requestFinancialParcel();
                }}
              >
                <span>Confirmar</span>
              </Button>
            </ContainerFlex>
          </St.BoxCard>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default AntecipationConference;
