/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
import Container from '~/components/container';
import { Paper } from '../styles';
import Select from '~/components/select';
import Input from '~/components/input';
import * as S from './styles';
import ContainerFlex from '~/components/containerFlex';
import Button from '~/components/button';
import RadioButton from '~/components/radioButton';
import AutoCompleteSelect, { IOption } from '~/components/AutoCompleteSelect';
import AutoCompleteSelectMultiple from '~/components/AutoCompleteSelectMultiple';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import THEMES from '~/themes/themes';
import Loading from '~/components/loading';
import Checkbox from '~/components/checkbox';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';

export interface IDeadlineResponse {
  quantidade: number;
  replicarParaTodos: boolean;
  tipoEventoProduto: string;
  tipoPeriodo: string;
}

export interface IConfigResponse {
  funcionarios: Funcionario[];
  ativo: boolean;
  perfis: string[];
  replicarParaTodos: boolean;
  tipoDistribuicao: string;
  tipoEventoProduto: string;
}

export interface Funcionario {
  id: string;
  nome: string;
}

const RenderChips = ({
  array,
  onDelete,
}: {
  array: [];
  onDelete: (item) => void;
}) => {
  if (!array?.length) return null;
  return (
    <S.Paper style={{ marginTop: 8, maxHeight: '300px', overflowY: 'scroll' }}>
      <div
        style={{
          flexWrap: 'wrap',
          display: 'flex',
          gap: 8,
        }}
      >
        {array.map((e) => {
          return (
            <S.Chips>
              <div>
                <span>{e.value}</span>
                <FiTrash2 onClick={() => onDelete(e)} />
              </div>
            </S.Chips>
          );
        })}
      </div>
    </S.Paper>
  );
};
const CF = ContainerFlex;
const EVENT_TYPES = [
  { id: 'LEAD_BENEFICIO', value: 'Benefício - Lead' },
  { id: 'CARTAO_UPGRADE', value: 'Cartão - Upgrade' },
  // { id: 'CARTAO_DOWNGRADE', value: 'Cartão - Downgrade' },
  // { id: 'CONTA_CANCELAMENTO', value: 'Conta - Cancelamento' },
];
const PERIOD_TYPES = [
  { id: 'DIA', value: 'Dia' },
  { id: 'HORA', value: 'Hora' },
  { id: 'MES', value: 'Mês' },
];

interface IForm {
  rule: 'AUTOMATICA' | 'MANUAL';
  tipoEvento: string;
  tipoPeriodo: string;
  qtdPeriodo: number | string;
  funcionariosAutomatico: IOption[];
  perfisAutomatico: IOption[];
  funcionariosManual: IOption[];
  perfisManual: IOption[];
  replicarParaTodosPrazo: boolean;
  replicarParaTodosManual: boolean;
  replicarParaTodosAutomatico: boolean;
}
export default function EventsCenterParameters(): React.ReactNode {
  const [form, setForm] = useState<IForm>({
    tipoEvento: '',
    tipoPeriodo: '',
    qtdPeriodo: null,
    funcionariosAutomatico: [],
    perfisAutomatico: [],
    funcionariosManual: [],
    perfisManual: [],
    rule: 'AUTOMATICA',
    replicarParaTodosPrazo: false,
    replicarParaTodosManual: false,
    replicarParaTodosAutomatico: false,
  });
  const [modalInfo, setModalInfo] = useState({
    open: false,
    message: '',
  });
  const [search, setSearch] = useState(false);
  const handleChangeForm = ({ name, value }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const GetOperators = useApi(Endpoints.report.getOperators, ApiMethod.GET);
  const GetProfiles = useApi(Endpoints.consult.getAllProfiles, ApiMethod.GET);

  const GetEventsDistribution = useApi<IConfigResponse>(
    Endpoints.parameters.getEventsDistribution,
    ApiMethod.GET,
    {},
    true,
  );
  const GetEventsDeadline = useApi<IDeadlineResponse>(
    Endpoints.parameters.getEventsDeadline,
    ApiMethod.GET,
    {},
    true,
  );
  const UpdateEventsDeadline = useApi(
    Endpoints.parameters.updateEventsDeadline,
    ApiMethod.POST,
  );
  const UpdateEventsDistribution = useApi(
    Endpoints.parameters.updateEventsDistribution,
    ApiMethod.POST,
  );

  useEffect(() => {
    (async () => {
      await GetOperators.callApi();
      await GetProfiles.callApi();
    })();
  }, []);
  const [loading, setLoading] = useState(false);

  const handleSearchParameters = async () => {
    const { data: automatic } = await GetEventsDistribution.callApi({
      tipoDistribuicao: 'AUTOMATICA',
      tipoEventoProduto: form.tipoEvento,
    });
    const { data: manual } = await GetEventsDistribution.callApi({
      tipoDistribuicao: 'MANUAL',
      tipoEventoProduto: form.tipoEvento,
    });
    const { data: deadline } = await GetEventsDeadline.callApi({
      tipoEventoProduto: form.tipoEvento,
    });
    setForm((prev) => ({
      ...prev,
      rule: automatic?.ativo ? 'AUTOMATICA' : 'MANUAL',
      replicarParaTodosManual: manual?.replicarParaTodos,
      replicarParaTodosAutomatico: automatic?.replicarParaTodos,
      funcionariosManual:
        manual?.funcionarios?.map((e) => ({
          id: e.id,
          value: e.nome,
        })) || [],
      funcionariosAutomatico:
        automatic?.funcionarios?.map((e) => ({
          id: e.id,
          value: e.nome,
        })) || [],
      perfisAutomatico:
        automatic?.perfis?.map((e) => {
          const found = GetProfiles.data.find((p) => p.value === e);
          return { id: found.value, value: found?.name };
        }) || [],
      perfisManual:
        manual?.perfis?.map((e) => {
          const found = GetProfiles.data.find((p) => p.value === e);
          return { id: found.value, value: found?.name };
        }) || [],
      qtdPeriodo: deadline?.quantidade,
      replicarParaTodosPrazo: deadline?.replicarParaTodos,
      tipoPeriodo: deadline?.tipoPeriodo,
    }));
    setSearch(true);
  };

  console.log(form);
  const handleUpdateDeadline = async () => {
    const response = await UpdateEventsDeadline.callApi({
      quantidade: form.qtdPeriodo,
      tipoEventoProduto: form.tipoEvento,
      tipoPeriodo: form.tipoPeriodo,
      replicarParaTodos: form.replicarParaTodosPrazo,
    });
    if (response.status >= 400) {
      setModalInfo({
        open: true,
        message: response.mensagem ?? 'Houve um erro ao salvar!',
      });
      return;
    }
    setModalInfo({ open: true, message: 'Salvo com sucesso!' });
  };

  const handleSaveDistributionConfig = async () => {
    const distAuto = form.rule === 'AUTOMATICA';
    const bodyAuto = {
      ativo: distAuto,
      funcionarios: form.funcionariosAutomatico.map((e) => ({
        id: e.id,
        nome: e.value,
      })),
      perfis: form.perfisAutomatico.map((e) => e.id),
      replicarParaTodos: form.replicarParaTodosAutomatico,
      tipoDistribuicao: form.rule,
      tipoEventoProduto: form.tipoEvento,
    };
    const bodyManual = {
      ativo: !distAuto,
      funcionarios: form.funcionariosManual.map((e) => ({
        id: e.id,
        nome: e.value,
      })),
      perfis: form.perfisManual.map((e) => e.id),
      replicarParaTodos: form.replicarParaTodosManual,
      tipoDistribuicao: form.rule,
      tipoEventoProduto: form.tipoEvento,
    };
    const response = await UpdateEventsDistribution.callApi(
      distAuto ? bodyAuto : bodyManual,
    );
    if (response.status >= 400) {
      setModalInfo({
        open: true,
        message: response.mensagem ?? 'Houve um erro ao salvar!',
      });
      return;
    }
    setModalInfo({ open: true, message: 'Salvo com sucesso!' });
  };

  return (
    <Container>
      <Loading
        status={
          GetEventsDeadline.loading ||
          GetEventsDistribution.loading ||
          UpdateEventsDeadline.loading ||
          UpdateEventsDistribution.loading ||
          GetOperators.loading ||
          GetProfiles.loading
        }
      />
      <Dialog
        icon={icons.warning}
        title={modalInfo.message}
        open={modalInfo.open}
        positiveLabel="OK"
        positiveAction={() => setModalInfo({})}
      />
      <Paper>
        <S.TitleContainer>
          <p>TeuCard - Central de eventos</p>
          <span>
            Selecione o tipo de produto e evento para carregar os parâmetros:
          </span>
          <CF row gap="16">
            <CF>
              <Select
                label="Tipo de produto e evento"
                name="tipoEvento"
                value={form.tipoEvento}
                options={EVENT_TYPES}
                onChange={(e) => {
                  setSearch(false);
                  handleChangeForm({ name: 'tipoEvento', value: e });
                }}
              />
            </CF>
            <CF>
              <Button fitContent onClick={handleSearchParameters}>
                Buscar
              </Button>
            </CF>
          </CF>
        </S.TitleContainer>

        {search && (
          <CF marginTop={24} gap="16">
            <S.Paper id="periodContainer">
              <CF id="periodContainer" gap="16">
                <CF row space center style={{ borderBottom: '1px solid gray' }}>
                  <CF>
                    <S.SubTitle>
                      Prazo para aplicar a data limite do evento
                    </S.SubTitle>
                  </CF>
                  <CF end>
                    <Button
                      size="small"
                      fitContent
                      onClick={handleUpdateDeadline}
                    >
                      Salvar
                    </Button>
                  </CF>
                </CF>

                <CF row center gap="24">
                  <CF maxWidth={30}>
                    <Select
                      label="Tipo de período"
                      name="tipoPeriodo"
                      value={form.tipoPeriodo}
                      options={PERIOD_TYPES}
                      onChange={(e) => {
                        handleChangeForm({ name: 'tipoPeriodo', value: e });
                      }}
                    />
                  </CF>
                  <CF maxWidth={30}>
                    <Input
                      edit
                      label="Quantidade"
                      name="qtdPeriodo"
                      value={form.qtdPeriodo}
                      onChange={(e) =>
                        handleChangeForm({
                          name: 'qtdPeriodo',
                          value: e.target.value,
                        })
                      }
                    />
                  </CF>
                </CF>
                <CF row gap="4">
                  <RadioButton
                    name="replicarParaTodosAutomatico"
                    checked={form.replicarParaTodosPrazo}
                    onChange={(e) =>
                      handleChangeForm({
                        name: 'replicarParaTodosPrazo',
                        value: e.target.checked,
                      })
                    }
                  />
                  <span>Aplicar em todos os tipos de produtos e eventos</span>
                </CF>
              </CF>
            </S.Paper>

            <S.Paper>
              <CF row space center style={{ borderBottom: '1px solid gray' }}>
                <CF>
                  <S.SubTitle>
                    Regra para distribuir os eventos para decisão
                  </S.SubTitle>
                </CF>
                <CF end>
                  <Button
                    size="small"
                    fitContent
                    onClick={handleSaveDistributionConfig}
                  >
                    Salvar
                  </Button>
                </CF>
              </CF>

              <CF
                space
                align="flex-start"
                justifyContent="space-between"
                gap="16"
                marginTop={16}
              >
                <S.RadioOptionContainer disabled={form.rule !== 'AUTOMATICA'}>
                  <CF marginTop={8} row gap="4">
                    <RadioButton
                      onChange={(e) =>
                        handleChangeForm({
                          name: 'rule',
                          value: 'AUTOMATICA',
                        })
                      }
                      checked={form.rule === 'AUTOMATICA'}
                    />
                    <span className="title">
                      Distribuir proporcional para funcionarios específicos ou
                      perfil
                    </span>
                  </CF>

                  <span className="subtitle">
                    Defina abaixo quem será atribuido como responsável (fila de
                    distribuição)
                  </span>

                  <CF
                    className="selectContainer"
                    row
                    align="flex-start"
                    gap="24"
                  >
                    <CF maxWidth={50}>
                      <AutoCompleteSelectMultiple
                        showChips={false}
                        limitTag={5}
                        value={form.funcionariosAutomatico}
                        label="Funcionários"
                        onChange={(value) =>
                          setForm((prev) => ({
                            ...prev,
                            funcionariosAutomatico: value,
                          }))
                        }
                        options={GetOperators.data?.map((e) => ({
                          id: e.id,
                          value: e.nome,
                        }))}
                      />

                      <RenderChips
                        onDelete={(item) =>
                          setForm((prev) => ({
                            ...prev,
                            funcionariosAutomatico: prev.funcionariosAutomatico.filter(
                              (p) => p.id !== item.id,
                            ),
                          }))
                        }
                        array={form.funcionariosAutomatico}
                      />
                    </CF>
                    <CF maxWidth={50}>
                      <AutoCompleteSelectMultiple
                        showChips={false}
                        limitTag={5}
                        value={form.perfisAutomatico}
                        label="Perfis"
                        onChange={(value) =>
                          setForm((prev) => ({
                            ...prev,
                            perfisAutomatico: value,
                          }))
                        }
                        options={GetProfiles.data?.map((e) => ({
                          id: e.value,
                          value: e.name,
                        }))}
                      />

                      <RenderChips
                        onDelete={(item) =>
                          setForm((prev) => ({
                            ...prev,
                            perfisAutomatico: prev.perfisAutomatico.filter(
                              (p) => p.id !== item.id,
                            ),
                          }))
                        }
                        array={form.perfisAutomatico}
                      />
                    </CF>
                  </CF>
                  <CF
                    className="radioReplicar"
                    center
                    marginTop={8}
                    row
                    gap="4"
                  >
                    <Checkbox
                      containerProps={{ style: { marginTop: 0 } }}
                      onChangeStatus={(status) =>
                        handleChangeForm({
                          name: 'replicarParaTodosAutomatico',
                          value: status,
                        })
                      }
                      value={form.replicarParaTodosAutomatico}
                    />
                    <span className="title">
                      Aplicar em todos os tipos de produtos e eventos
                    </span>
                  </CF>
                </S.RadioOptionContainer>

                <S.RadioOptionContainer
                  disabled={form.rule !== 'MANUAL'}

                  // maxWidth: form.rule === 'MANUAL' ? '80%' : '20%',
                  // transition: 'all 150ms ease-in',
                >
                  <CF marginTop={8} row gap="4">
                    <RadioButton
                      onChange={(e) =>
                        handleChangeForm({ name: 'rule', value: 'MANUAL' })
                      }
                      checked={form.rule === 'MANUAL'}
                    />
                    <span className="title">
                      Disponível na caixa da central. Livre para ser atribuido
                      por usuário ou perfil.
                    </span>
                  </CF>

                  <span className="subtitle">
                    Definir abaixo quem pode enxergar eventos pendentes de
                    decisão:
                  </span>

                  <CF
                    className="selectContainer"
                    row
                    align="flex-start"
                    gap="24"
                  >
                    <CF maxWidth={50}>
                      <AutoCompleteSelectMultiple
                        showChips={false}
                        limitTag={5}
                        value={form.funcionariosManual}
                        label="Funcionários"
                        onChange={(value) =>
                          setForm((prev) => ({
                            ...prev,
                            funcionariosManual: value,
                          }))
                        }
                        options={GetOperators.data?.map((e) => ({
                          id: e.id,
                          value: e.nome,
                        }))}
                      />
                      <RenderChips
                        onDelete={(item) =>
                          setForm((prev) => ({
                            ...prev,
                            funcionariosManual: prev.funcionariosManual.filter(
                              (p) => p.id !== item.id,
                            ),
                          }))
                        }
                        array={form.funcionariosManual}
                      />
                    </CF>
                    <CF maxWidth={50}>
                      <AutoCompleteSelectMultiple
                        showChips={false}
                        limitTag={5}
                        value={form.perfisManual}
                        label="Perfis"
                        onChange={(value) =>
                          setForm((prev) => ({
                            ...prev,
                            perfisManual: value,
                          }))
                        }
                        options={GetProfiles.data?.map((e) => ({
                          id: e.value,
                          value: e.name,
                        }))}
                      />

                      <RenderChips
                        onDelete={(item) =>
                          setForm((prev) => ({
                            ...prev,
                            perfisManual: prev.perfisManual.filter(
                              (p) => p.id !== item.id,
                            ),
                          }))
                        }
                        array={form.perfisManual}
                      />
                    </CF>
                  </CF>
                  <CF
                    className="radioReplicar"
                    center
                    marginTop={8}
                    row
                    gap="4"
                  >
                    <Checkbox
                      containerProps={{ style: { marginTop: 0 } }}
                      onChangeStatus={(status) =>
                        handleChangeForm({
                          name: 'replicarParaTodosManual',
                          value: status,
                        })
                      }
                      value={form.replicarParaTodosManual}
                    />
                    <span className="title">
                      Aplicar em todos os tipos de produtos e eventos
                    </span>
                  </CF>
                </S.RadioOptionContainer>
              </CF>
            </S.Paper>
          </CF>
        )}
      </Paper>
    </Container>
  );
}
