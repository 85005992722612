import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Container from '~/components/container';
import * as St from '../styles';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import { icons } from '~/assets';
import Input from '~/components/input';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Button from '~/components/button';
import PictureModal from '~/components/pictureModal';
import { ImageZoomRotate } from '~/components/ImageZoomRotate';
import Dialog from '~/components/dialog';

interface StateType {
  id: string;
}
type Item = {
  codRetorno: string;
  protocolo: string;
  service: string;
  status: string;
  mensagem: string;
};

interface IBiometricDetails extends IErrorResponse {
  dataNascimento: string;
  imageId: string;
  itens: Item[];
  nomeCompleto: string;
  ticket: string;
}

interface ImageProps {
  image: string;
}

interface IErrorResponse {
  mensagem: string;
  status: number;
}

const FacialBiometricsDetail = () => {
  const [data, setData] = useState<IBiometricDetails>({} as IBiometricDetails);
  const [openDialog, setOpenDialog] = useState(false);
  const [image, setImage] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [serviceBiometrics, setServiceBiometrics] = useState('');

  const { state } = useLocation<StateType>();
  const history = useHistory();

  const RequestBiometricsDetails = useApi<IBiometricDetails>(
    Endpoints.integration.getFacialBiometricsDetail,
    ApiMethod.GET,
    {},
    true,
  );

  const RequestFacialBiometricImage = useApi<ImageProps>(
    `${Endpoints.integration.getImageFacialBiometric}/${state.id}/${data.imageId}
    `,
    ApiMethod.GET,
  );

  const getBiometricsDetails = async () => {
    try {
      const response = await RequestBiometricsDetails.callApi({
        ticket: state.id,
      });
      if (response.status === 200) {
        setData(response?.data);
        setServiceBiometrics(response?.data.itens[0]?.service);
      } else if (response.status >= 400) {
        setDialogError(true);
        setMessageError(
          response.data.mensagem
            ? response.data.mensagem
            : 'Erro ao consultar os detalhes',
        );
      }
    } catch (error) {
      console.log(error.mensagem);
      setDialogError(true);
    }
  };

  console.log(data);

  const getImageFacialBiometric = async () => {
    try {
      const response = await RequestFacialBiometricImage.callApi();
      const { image } = response.data;
      setImage(image);
      setImageLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.id) {
      getBiometricsDetails();
    }
  }, [state]);

  useEffect(() => {
    if (openDialog && !imageLoaded) {
      getImageFacialBiometric();
    }
  }, [openDialog, imageLoaded]);

  const handleTogglePicture = () => setOpenDialog((prev) => !prev);

  return (
    <>
      {openDialog && (
        <PictureModal handleClose={handleTogglePicture}>
          <div
            style={{
              position: 'relative',
              overflow: 'hidden',
              background: '#fff',
              height: '100%',
            }}
          >
            <ImageZoomRotate
              image={
                image ? `data:image/jpeg;base64, ${image}` : icons.userAvatar
              }
            />
          </div>
        </PictureModal>
      )}

      <Loading
        status={
          RequestBiometricsDetails.loading ||
          RequestFacialBiometricImage.loading
        }
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogError}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogError(false);
          history.push('/consult/user/biometrics');
        }}
        message={messageError}
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex padding={30}>
            <ContainerFlex row center marginBottom={60}>
              <St.Icon src={icons.exclamation} />
              <St.TitleHeader>
                <span style={{ color: '#e71037' }}>
                  Detalhes biometria facial
                </span>
                <img src={image} alt="" />
              </St.TitleHeader>
            </ContainerFlex>
            <Grid xs={1} sm={2} lg={2} gap={30}>
              {serviceBiometrics === 'LIVENESS' ? (
                <ContainerFlex>
                  <St.Subtitle>1. Resultado do Liveness</St.Subtitle>
                  <ContainerFlex marginLeft={20} marginTop={20}>
                    <Input
                      readOnly
                      label="Código de retorno"
                      value={
                        serviceBiometrics === 'LIVENESS'
                          ? data?.itens[0]?.codRetorno
                          : data?.itens[1]?.codRetorno
                      }
                    />
                  </ContainerFlex>
                  <ContainerFlex marginLeft={20} marginTop={20}>
                    <Input
                      readOnly
                      label="Protocolo"
                      value={
                        serviceBiometrics === 'LIVENESS'
                          ? data?.itens[0]?.protocolo
                          : data?.itens[1]?.protocolo
                      }
                    />
                  </ContainerFlex>
                  <ContainerFlex marginLeft={20} marginTop={20}>
                    <Input
                      readOnly
                      label="Status"
                      value={
                        serviceBiometrics === 'LIVENESS'
                          ? data?.itens[0]?.status
                          : data?.itens[1]?.status
                      }
                    />
                  </ContainerFlex>
                  <ContainerFlex marginLeft={20} marginTop={20}>
                    <Input readOnly label="Nome" value={data.nomeCompleto} />
                  </ContainerFlex>
                  <ContainerFlex marginLeft={20} marginTop={20}>
                    <Input
                      readOnly
                      label="Data de nascimento"
                      value={data.dataNascimento}
                    />
                  </ContainerFlex>
                </ContainerFlex>
              ) : null}
              {data?.itens?.length > 1 || serviceBiometrics === 'BUREAU' ? (
                <ContainerFlex>
                  <>
                    <St.Subtitle>
                      2 - Resultado validação na base de faces
                    </St.Subtitle>
                    <ContainerFlex marginLeft={20} marginTop={20}>
                      <Input
                        readOnly
                        label="Código de retorno"
                        value={
                          serviceBiometrics === 'BUREAU'
                            ? data?.itens[0]?.codRetorno
                            : data?.itens[1]?.codRetorno
                        }
                      />
                    </ContainerFlex>
                    <ContainerFlex marginLeft={20} marginTop={20}>
                      <Input
                        readOnly
                        label="Protocolo"
                        value={
                          serviceBiometrics === 'BUREAU'
                            ? data?.itens[0]?.protocolo
                            : data?.itens[1]?.protocolo
                        }
                      />
                    </ContainerFlex>
                    <ContainerFlex marginLeft={20} marginTop={20}>
                      <Input
                        readOnly
                        label="Status"
                        value={
                          serviceBiometrics === 'BUREAU'
                            ? data?.itens[0]?.status
                            : data?.itens[1]?.status
                        }
                      />
                    </ContainerFlex>
                    <ContainerFlex marginLeft={20} marginTop={20}>
                      <Input
                        readOnly
                        label="Mensagem"
                        value={
                          serviceBiometrics === 'BUREAU'
                            ? data?.itens[0]?.mensagem
                            : data?.itens[1]?.mensagem
                        }
                      />
                    </ContainerFlex>
                    {serviceBiometrics === 'BUREAU' && (
                      <ContainerFlex marginLeft={20} marginTop={20}>
                        <Input
                          readOnly
                          label="Nome"
                          value={data.nomeCompleto}
                        />
                      </ContainerFlex>
                    )}
                  </>

                  <ContainerFlex marginLeft={20} marginTop={20}>
                    <Input readOnly label="Ticket" value={data.ticket} />
                  </ContainerFlex>
                </ContainerFlex>
              ) : null}
            </Grid>
            <ContainerFlex row end marginTop={30} gap="30">
              <Button outlined fitContent onClick={() => history.goBack()}>
                Voltar
              </Button>
              <Button fitContent onClick={() => setOpenDialog(true)}>
                Visualizar imagem
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default FacialBiometricsDetail;
