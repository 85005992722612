import { useHistory } from 'react-router-dom';
import Container from '~/components/container';
import * as S from './styles';
import Button from '~/components/button';

const BoxItem = ({ title, href }) => {
  const history = useHistory();
  return (
    <S.BoxItem>
      <p>{title}</p>
      <Button size="medium" onClick={() => history.push(href)}>
        Visualizar
      </Button>
    </S.BoxItem>
  );
};

export default function ParametersIndex(): React.ReactNode {
  return (
    <Container>
      <S.Paper>
        <BoxItem
          title="TeuCard - Central de eventos"
          href="/parameters/events-center"
        />
      </S.Paper>
    </Container>
  );
}
