/* eslint-disable react/jsx-curly-newline */
import { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { IEvent, IProduct, LeadContext } from '~/contexts/leads';
import useApi, { ApiMethod } from '~/hooks/useApi';
import ConsultUserSearchResponse from '~/services/consult/user/search/response';
import Endpoints from '~/services/endpoints';
import Select from '../Components/select';
import {
  IAvailableProducts,
  IClientPhoto,
  IFormDate,
  ILoadingTypes,
  IPhoneZeuss,
  IThemeCard,
  LeadContactHistory,
} from '../Interfaces/sharedInterfaces';
import Constants from '~/utils/constants';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import * as St from './styles';
import Button from '~/components/button';
import { components, icons } from '~/assets/index';
import { SharedCardServicesContext } from '../Card/Services/sharedCardServiceContext';
import TextArea from '~/components/textarea';
import Input, { MaskTypes } from '~/components/input';
import TableList from '~/components/tableList';
import { headerContactHistory } from '../Headers/globalHeaders';
import { validateDate } from '../utils/supports';
import { formatTime, validateTime } from '~/utils/supports';
import SharedServiceContext from './sharedServiceContext';
import SharedServiceWebSocket from './sharedServiceWebSocket';

const SharedServiceProvider = ({ children }) => {
  const history = useHistory();
  const { resetContextCard } = useContext(SharedCardServicesContext);
  const { addLeadData, leads } = useContext(LeadContext);
  const { leadDetail } = leads;
  const [cpfGlobal, setCpfGlobal] = useState<string>('');
  const [nameGlobal, setNameGlobal] = useState<string>('');
  const [availableProducts, setAvailableProducts] = useState<
    IAvailableProducts[]
  >();
  const [cardInfos, setCardInfos] = useState<IThemeCard>();
  const [messageActive, setMessageActive] = useState<boolean>(false);
  const [messageText, setMessageText] = useState<string>('');
  const [eventId, setEventId] = useState<string>('');
  const [opinion, setOpinion] = useState<string>('');
  const [clientData, setClientData] = useState<any>('');
  const [product, setProduct] = useState<IProduct>();
  const [event, setEvent] = useState<IEvent>();
  const [toFowardModal, setToFowardModal] = useState(false);
  const [nextContactModal, setNextContactModal] = useState(false);
  const [modalDescriptionOpen, setModalDescriptionOpen] = useState(false);
  const [retProfiles, setRetProfiles] = useState<any>();
  const [profileSelected, setProfileSelected] = useState<any>();
  const [contactHistoryList, setContactHistoryList] = useState([]);
  const [contactType, setContactType] = useState('');
  const [executeEventLoading, setExecuteEventLoading] = useState(false);
  const [formDate, setFormDate] = useState<IFormDate>({
    nextDate: { unmasked: '', masked: '' },
    nextHour: '00:00',
  });
  const [cardModelSelected, setCardModelSelected] = useState<string>('');
  const [nextContactError, setNextContactError] = useState(false);
  const [startWebSocket, setStartWebSocket] = useState(false);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<File>();
  const [phoneZeuss, setPhoneZeuss] = useState<IPhoneZeuss>({
    masked: '',
    unmasked: '',
  });
  const operator = JSON.parse(localStorage.getItem('@NovaEra:user'));
  const [profile, setProfile] = useState(
    (
      operator?.perfis?.filter((profile: string) => profile !== 'USUARIO') || []
    ).join(','),
  );
  const [disabledByProfile, setDisabledByProfile] = useState(false);
  const [listArrayCancelType, setListArrayCancelType] = useState<any>([]);

  const disabledButton = [
    'CANCELAMENTO_PROPOSTA',
    'CANCELAMENTO_SOLICITACAO',
    'CANCELAMENTO_EXPERIENCIA',
    'CANCELAMENTO_INATIVIDADE',
    'INADIMPLENTE',
    'COBRANCA',
  ].includes(clientData.statusCartao);
  const disableButtonBenefit = !['ATIVO', 'INATIVO'].includes(
    clientData.statusCartao,
  );

  useEffect(() => {
    if (profile)
      if (profile !== 'ADM' && profile !== 'SUP' && profile !== 'RET')
        setDisabledByProfile(true);
  }, [profile]);

  const getUserInfo = useApi<ConsultUserSearchResponse>(
    Endpoints.events.getEvent,
    ApiMethod.GET,
    {},
    true,
  );

  const includeEvent = useApi<any>(
    Endpoints.events.postIncludeEvent,
    ApiMethod.POST,
  );

  const getAvailableProductTypes = useApi<IAvailableProducts[]>(
    Endpoints.events.getAvailableProductTypes,
    ApiMethod.GET,
  );

  const getThemeClientCategory = useApi<IThemeCard>(
    `${Endpoints.card.getClientCurrentCategory.replace(
      'PARAM_CPF',
      cpfGlobal,
    )}`,
    ApiMethod.GET,
  );

  const getClientPhoto = useApi<IClientPhoto>(
    Endpoints.events.getClientPhoto.replace('PARAM_CPF', cpfGlobal),
    ApiMethod.GET,
  );

  const getRetProfiles = useApi(Endpoints.events.getRetProfiles, ApiMethod.GET);

  const closeLead = useApi(Endpoints.events.postCloseLead, ApiMethod.POST);

  const postForwardLead = useApi(
    Endpoints.events.postForwardLead,
    ApiMethod.POST,
  );

  const getContactHistory = useApi<LeadContactHistory[]>(
    Endpoints.events.getContactHistory.replace('PARAM_ID', eventId),
    ApiMethod.GET,
  );

  const postNewContact = useApi(
    Endpoints.events.postNextContact,
    ApiMethod.POST,
  );

  const getClientInformation = async () => {
    try {
      const response = await getUserInfo.callApi({
        cpf: cpfGlobal,
        nome: nameGlobal,
      });
      if (response.status >= 200 && response.status < 300) {
        setCpfGlobal(response.data.cpf);
        addLeadData('leadDetail', response.data);
        setClientData(response.data);
      } else {
        setMessageActive(true);
        setMessageText(response.mensagem);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getAvailableProducts = async () => {
    try {
      const response = await getAvailableProductTypes.callApi();
      if (response.status >= 200 && response.status < 300) {
        setAvailableProducts(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getInfoCards = async () => {
    try {
      const response = await getThemeClientCategory.callApi();
      if (response.status >= 200 && response.status < 300) {
        setCardInfos(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getPhoto = async () => {
    try {
      const response = await getClientPhoto.callApi();
      if (response.status >= 200 && response.status < 300) {
        if (response.data?.base64?.length > 200) {
          addLeadData('photo', response.data?.base64);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getProfiles = async () => {
    try {
      const response = await getRetProfiles.callApi();
      if (response.status >= 200 && response.status < 300) {
        setRetProfiles(response);
        setToFowardModal(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const postIncludeEvent = async () => {
    try {
      const response = await includeEvent.callApi({
        cpf: cpfGlobal,
        nome: clientData?.cliente,
        celular: clientData?.celular,
        email: clientData?.email,
        operador: {
          idOperador: localStorage.getItem(Constants.USER_ID),
          nome: operator?.nomeTitular,
          perfil: (
            operator.perfis?.filter(
              (profile: string) => profile !== 'USUARIO',
            ) || []
          ).join(','),
        },
        origem: clientData?.origem ?? 'MANUAL',
        produto: product.id,
        tipoEvento: event.id,
      });
      if (response.status >= 200 && response.status < 300) {
        if (product.value === 'Cartão') {
          if (event.id === 'DOWNGRADE') {
            history.push('/sales/eventCenter/downgradeDecision', {
              cpfGlobal,
              product,
              event,
              eventId: response.data?.id,
            });
          }
          if (event.id === 'UPGRADE') {
            history.push('/sales/eventCenter/SolicitationDetails', {
              cpfGlobal,
              product,
              event,
              eventId: response.data?.id,
            });
          }
        } else if (product.value === 'Conta') {
          history.push('/sales/eventCenter/SolicitationDetails', {
            cpfGlobal,
            product,
            event,
            eventId: response.data?.id,
          });
        }
      } else {
        setMessageActive(true);
        setMessageText(response.mensagem);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const postCloseLead = async () => {
    const response = await closeLead.callApi({
      idEvento: eventId,
      parecer: opinion,
    });

    if (response.status >= 200 && response.status < 300) {
      setMessageText('Evento encerrado com sucesso!');
      setMessageActive(true);
    } else {
      setMessageText('Houve um erro ao encerrar o evento!');
      setMessageActive(true);
    }
  };

  const handleForwardLead = async () => {
    const newOperator = retProfiles.data.find(
      (e) => e.idOperador === profileSelected,
    );

    const body = {
      cpf: cpfGlobal,
      idEvento: eventId,
      nome: clientData.cliente,
      operador: {
        idOperador: localStorage.getItem(Constants.USER_ID),
        nome: operator?.nomeTitular,
      },
      novoOperador: newOperator,
      origem: clientData?.origem ?? 'MANUAL',
      produto: product.id,
      tipoEvento: event.id,
    };
    const response = await postForwardLead.callApi(body);
    if (response.status === 201) {
      setMessageActive(true);
      setMessageText('Encaminhamento realizado com sucesso!');
    } else {
      setMessageActive(true);
      setMessageText(response.mensagem);
    }
  };

  const openForwardLead = async () => {
    const response = await getContactHistory.callApi();
    if (response.data && response.data?.length > 0) {
      const newList = [];

      response.data.forEach((contact) => {
        const item = [
          {
            border: true,
            value: contact?.dataContato
              .split('T')[0]
              .split('-')
              .reverse()
              .join('/'),
            maxChar: 30,
            textAlign: 'center',
          },
          {
            border: true,
            value: contact?.parecer,
            maxChar: 15,
            textAlign: 'center',
            render: () => {
              return (
                <St.DescContainer
                  onClick={() => {
                    setModalDescriptionOpen(true);
                    setMessageText(contact?.parecer);
                  }}
                >
                  <components.Warning fontSize={12} />
                </St.DescContainer>
              );
            },
          },
          {
            border: true,
            value: contact?.tipoContato,
            maxChar: 15,
            textAlign: 'center',
          },
          {
            border: true,
            value: contact?.operador?.nome,
            maxChar: 20,
            textAlign: 'center',
          },
          {
            value: contact?.dataProximoContato
              .split('T')[0]
              .split('-')
              .reverse()
              .join('/'),
            maxChar: 8,
            textAlign: 'center',
            border: true,
          },
          {
            value: contact?.dataProximoContato?.split('T')[1],
            maxChar: 8,
            textAlign: 'center',
            border: true,
          },
        ];
        newList.push(item);
      });
      setContactHistoryList(newList);
    }
    setNextContactModal(true);
  };

  const newContact = async () => {
    const body = {
      cpf: cpfGlobal,
      dataProximoContato: `${formDate?.nextDate?.masked
        ?.split('/')
        .reverse()
        .join('-')}T${formDate.nextHour}:00`,
      idEvento: eventId,
      nome: clientData.nome ?? clientData.cliente,
      operador: {
        idOperador: localStorage.getItem(Constants.USER_ID),
        nome: operator?.nomeTitular,
      },
      origem: clientData?.origem ?? 'MANUAL',
      parecer: opinion,
      produto: product.id,
      tipoContato: contactType,
      tipoEvento: event.id,
    };

    const response = await postNewContact.callApi(body);
    if (response.status >= 200 && response.status < 300) {
      setMessageText('Próximo contato realizado com sucesso!');
      setMessageActive(true);
    } else {
      setMessageText(response.mensagem);
      setMessageActive(true);
    }
  };
  const executeEvent = async (effect: boolean, flux: boolean) => {
    setExecuteEventLoading(true);
    const token = localStorage.getItem(Constants.TOKEN_KEY);
    const tokenBearer = localStorage.getItem(Constants.TOKEN_BEARER);
    const config = {
      headers: {
        apikey: `token ${token}`,
        Authorization: `Bearer ${tokenBearer}`,
      },
    };
    try {
      const formData = new FormData();
      formData.append('idEvento', eventId);
      formData.append('parecer', opinion);
      formData.append('recusar', recusar.toString());

      if (product?.value === 'Conta') {
        formData.append('tipoCancelamento', listArrayCancelType);
      } else if (product?.value === 'Cartão') {
        formData.append('celularZeuss', phoneZeuss.unmasked);
        formData.append('tema', cardModelSelected);
        formData.append('aprovar', effect.toString());
      }

      if (flux) {
        const fileType = file?.type?.split('/')[1];

        const newFileName =
          fileType.length > 0
            ? `${fileName}.${file.type.split('/')[1]}`
            : `${fileName}.wav`;
        formData.append('nomeArquivo', newFileName);
        formData.append('arquivo', file);
      }

      const response = await Axios.post(
        Endpoints.events.postFinishEvent.replace('PARAM_ID_EVENTO', eventId),
        formData,
        config,
      );
      if (response.status >= 200 && response.status < 300) {
        if (effect) setStartWebSocket(true);
        else {
          setMessageActive(true);
          setMessageText(response.data?.mensagem);
        }
      } else {
        setMessageActive(true);
        setMessageText(response.data?.mensagem);
      }
    } catch (err) {
      setMessageActive(true);
      setMessageText(
        err.request.response.split('"mensagem":"')[1].split('"')[0],
      );
    } finally {
      setExecuteEventLoading(false);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  const executeEventNew = async ({
    websocket,
    hasFile,
    payload = {},
    recusar = false,
  }: {
    websocket: boolean;
    hasFile: boolean;
    payload: {};
    recusar: boolean;
  }) => {
    setExecuteEventLoading(true);
    const token = localStorage.getItem(Constants.TOKEN_KEY);
    const tokenBearer = localStorage.getItem(Constants.TOKEN_BEARER);
    const config = {
      headers: {
        apikey: `token ${token}`,
        Authorization: `Bearer ${tokenBearer}`,
      },
    };
    try {
      if (product?.value === 'Cartão') {
        Object.assign(payload, {
          cartao: {
            aprovar: websocket,
            tema: cardModelSelected,
            celularZeuss: phoneZeuss.unmasked,
            parecer: opinion,
            recusar,
            // assinaturaImage: '',
          },
        });
      }

      if (hasFile) {
        const fileType = file?.type?.split('/')[1];

        const newFileName =
          fileType.length > 0
            ? `${fileName}.${file.type.split('/')[1]}`
            : `${fileName}.wav`;

        const base64 = await toBase64(file);
        Object.assign(payload.cartao, {
          nomeArquivo: newFileName,
          arquivo: base64?.split(',')[1],
        });
      }
      Object.assign(payload, {
        idOperador: localStorage.getItem(Constants.USER_ID),
      });

      const response = await Axios.post(
        Endpoints.events.postFinishEvent.replace('PARAM_ID_EVENTO', eventId),
        payload,
        config,
      );
      if (response.status >= 200 && response.status < 300) {
        if (websocket) setStartWebSocket(true);
        else {
          setMessageActive(true);
          setMessageText(response.data?.mensagem);
        }
      } else {
        setMessageActive(true);
        setMessageText(response.data?.mensagem);
      }
    } catch (err) {
      setMessageActive(true);

      setMessageText(err?.response?.data?.mensagem ?? 'Houve um erro');
    } finally {
      setExecuteEventLoading(false);
    }
  };
  const handleResetContext = () => {
    resetContext();
    resetContextCard();
  };

  const resetContext = () => {
    setCpfGlobal('');
    setNameGlobal('');
    setMessageActive(false);
    setMessageText('');
    setModalDescriptionOpen(false);
    setNextContactModal(false);
    setToFowardModal(false);
    setContactType('');
    setFormDate({
      nextDate: { unmasked: '', masked: '' },
      nextHour: '',
    });
    setEventId('');
    setOpinion('');
    setListArrayCancelType([]);
    setProduct(undefined);
    setEvent(undefined);
    setClientData('');
    setCardInfos(undefined);
    setAvailableProducts([]);
    setCardModelSelected('');
    setStartWebSocket(false);
  };

  const PostAtribuir = useApi(
    Endpoints.events.postAtribuirEvento,
    ApiMethod.POST,
  );
  const PostDesatribuir = useApi(
    Endpoints.events.postDesatribuirEvento,
    ApiMethod.POST,
  );

  const loadingGlobal: ILoadingTypes = {
    getUserInfo: { loading: getUserInfo.loading },
    includeEvent: { loading: includeEvent.loading },
    getAvailableProductTypes: { loading: getAvailableProductTypes.loading },
    getThemeClientCategory: { loading: getThemeClientCategory.loading },
    getClientPhoto: { loading: getClientPhoto.loading },
    closeLead: { loading: closeLead.loading },
    getRetProfiles: { loading: getRetProfiles.loading },
    postForwardLead: { loading: postForwardLead.loading },
    getContactHistory: { loading: getContactHistory.loading },
    postNewContact: { loading: postNewContact.loading },
    postExecuteEvent: { loading: executeEventLoading },
    atribuir: { loading: PostAtribuir.loading },
    desatribuir: { loading: PostDesatribuir.loading },
  };

  const handleAtribuir = async () => {
    const response = await PostAtribuir.callApi({
      idOperador: localStorage.getItem(Constants.USER_ID),
      idEvento: eventId,
    });
    return response;
  };
  const handleDesatribuir = async () => {
    const response = await PostDesatribuir.callApi({
      idOperador: localStorage.getItem(Constants.USER_ID),
      idEvento: eventId,
    });
    return response;
  };

  return (
    <SharedServiceContext.Provider
      value={{
        cpfGlobal,
        setCpfGlobal,
        nameGlobal,
        setNameGlobal,
        messageText,
        setMessageText,
        messageActive,
        setMessageActive,
        eventId,
        setEventId,
        opinion,
        setOpinion,
        listArrayCancelType,
        setListArrayCancelType,
        product,
        setProduct,
        event,
        setEvent,
        clientData,
        setClientData,
        cardInfos,
        availableProducts,
        setAvailableProducts,
        getAvailableProducts,
        getClientInformation,
        getInfoCards,
        getPhoto,
        postCloseLead,
        postIncludeEvent,
        loadingGlobal,
        resetContext,
        getProfiles,
        handleForwardLead,
        openForwardLead,
        cardModelSelected,
        setCardModelSelected,
        phoneZeuss,
        setPhoneZeuss,
        executeEventNew,
        executeEvent,
        disabledButton,
        disableButtonBenefit,
        disabledByProfile,
        modalDescriptionOpen,
        setModalDescriptionOpen,
        fileName,
        setFileName,
        file,
        setFile,
        handleAtribuir,
        handleDesatribuir,
      }}
    >
      <Dialog widthInPercent={60} open={modalDescriptionOpen}>
        <ContainerFlex center end padding={10}>
          <St.DialogContent>
            <St.Title style={{ textAlign: 'center' }}>{messageText}</St.Title>
            <ContainerFlex end row marginTop={60}>
              <Button
                onClick={() => {
                  setModalDescriptionOpen(false);
                }}
              >
                Fechar
              </Button>
            </ContainerFlex>
          </St.DialogContent>
        </ContainerFlex>
      </Dialog>
      <Dialog widthInPercent={60} open={nextContactModal}>
        <ContainerFlex center padding={10}>
          <Dialog
            widthInPercent={60}
            open={modalDescriptionOpen && nextContactModal}
          >
            <ContainerFlex center end padding={10}>
              <St.DialogContent>
                <St.Message>
                  <St.Title style={{ fontSize: 14 }}>{messageText}</St.Title>
                </St.Message>
                <ContainerFlex end row marginTop={60}>
                  <Button
                    onClick={() => {
                      setModalDescriptionOpen(false);
                    }}
                  >
                    Fechar
                  </Button>
                </ContainerFlex>
              </St.DialogContent>
            </ContainerFlex>
          </Dialog>
          <St.DialogContent>
            <St.Title style={{ textAlign: 'center' }}>
              Registrar contato
            </St.Title>
            <St.Title style={{ fontSize: '18px', marginBottom: '10px' }}>
              Histórico
            </St.Title>
            <ContainerFlex>
              <TableList
                list={contactHistoryList}
                customerHeader={headerContactHistory}
              />
            </ContainerFlex>
            <ContainerFlex row space center gap="20">
              <ContainerFlex>
                <Select
                  label="Tipo de contato"
                  name="contactType"
                  options={[{ id: 'TELEFONE', value: 'Telefone' }]}
                  value={contactType}
                  onChange={(e) => setContactType(e)}
                />
              </ContainerFlex>
              <ContainerFlex gap="20" row>
                <Input
                  label="Data do próximo contato"
                  name="dtNextContact"
                  value={formDate.nextDate?.masked}
                  mask={MaskTypes.DATE}
                  maxLength={10}
                  errorMessage={
                    (formDate.nextDate?.unmasked?.length > 0 &&
                      !validateDate(
                        new Date(
                          formDate.nextDate.masked
                            ?.split('/')
                            .reverse()
                            .join('-'),
                        ),
                      ) &&
                      'Data inválida') ||
                    (nextContactError && 'Data inválida')
                  }
                  onChange={(e) =>
                    setFormDate((prev) => ({
                      ...prev,
                      nextDate: e.target.value,
                    }))
                  }
                />
                <Input
                  label="Horário do próximo contato"
                  name="hrNextContact"
                  value={formDate.nextHour}
                  maxLength={5}
                  errorMessage={
                    formDate?.nextHour?.length > 0 &&
                    validateTime(formDate.nextHour) &&
                    'Formato esperado: HH:MM'
                  }
                  onChange={(e) =>
                    setFormDate((prev) => ({
                      ...prev,
                      nextHour: formatTime(e.target.value),
                    }))
                  }
                />
              </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex row marginTop={20} height={40}>
              <TextArea
                placeholder="Digite o parecer..."
                style={{ fontStyle: 'italic' }}
                rows={10}
                value={opinion}
                onChange={(e) => setOpinion(e.target.value)}
              />
            </ContainerFlex>

            <ContainerFlex row gap="10" end marginTop={15}>
              <Button outlined onClick={() => setNextContactModal(false)}>
                Voltar
              </Button>
              <Button
                disabled={
                  formDate?.nextDate?.unmasked?.length !== 8 ||
                  opinion?.length < 1 ||
                  !validateDate(
                    new Date(
                      formDate.nextDate.masked?.split('/').reverse().join('-'),
                    ),
                  )
                }
                loading={postNewContact.loading}
                onClick={() => newContact()}
              >
                Solicitar
              </Button>
            </ContainerFlex>
          </St.DialogContent>
        </ContainerFlex>
      </Dialog>
      <Dialog widthInPercent={60} open={toFowardModal}>
        <ContainerFlex center padding={10}>
          <St.DialogContent>
            <St.Title style={{ textAlign: 'center' }}>Encaminhar</St.Title>
            <St.Title style={{ textAlign: 'center', fontSize: 20 }}>
              Selecione o novo responsável pelo evento
            </St.Title>

            <ContainerFlex row space center gap="20">
              <ContainerFlex>
                <Select
                  label="Responsável"
                  name="retProfile"
                  // options={[
                  //   { id: 'TELEFONE', value: 'Telefone' },
                  //   { id: 'EMAIL', value: 'Email' },
                  //   { id: 'SMS', value: 'SMS' },
                  //   { id: 'WHATSAPP', value: 'Whatsapp' },
                  // ]}
                  options={retProfiles?.data?.map((e) => ({
                    value: e.nome,
                    id: e.idOperador,
                  }))}
                  value={profileSelected}
                  onChange={(e) => {
                    setProfileSelected(e);
                  }}
                />
              </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex
              row
              gap="10"
              end
              style={{ bottom: 0, right: 0, position: 'absolute' }}
            >
              <Button
                outlined
                onClick={() => {
                  setToFowardModal(false);
                }}
              >
                Voltar
              </Button>
              <Button
                loading={postForwardLead.loading}
                onClick={() => handleForwardLead()}
              >
                Encaminhar
              </Button>
            </ContainerFlex>
          </St.DialogContent>
        </ContainerFlex>
      </Dialog>
      <SharedServiceWebSocket
        cpf={cpfGlobal}
        approvedEvent={startWebSocket}
        typeEvent={event?.value}
      />
      <Dialog widthInPercent={60} open={modalDescriptionOpen}>
        <ContainerFlex center padding={10}>
          <St.DialogContent>
            <St.Title style={{ textAlign: 'center' }}>Encerrar evento</St.Title>

            <ContainerFlex row>
              <TextArea
                placeholder="Digite o parecer..."
                style={{ fontStyle: 'italic' }}
                rows={10}
                value={opinion}
                onChange={(e) => setOpinion(e.target.value)}
              />
            </ContainerFlex>

            <ContainerFlex
              row
              gap="10"
              end
              marginTop={10}
              style={{ bottom: 0, right: 0, position: 'absolute' }}
            >
              <Button outlined onClick={() => setModalDescriptionOpen(false)}>
                Voltar
              </Button>
              <Button
                loading={closeLead.loading}
                disabled={!opinion?.length}
                onClick={() => postCloseLead()}
              >
                Encerrar
              </Button>
            </ContainerFlex>
          </St.DialogContent>
        </ContainerFlex>
      </Dialog>
      <Dialog
        open={messageActive}
        positiveAction={() => {
          handleResetContext();
          history.push('/sales/eventCenter');
        }}
        message={messageText}
        positiveLabel="Ok"
        icon={icons.warning}
        widthInPixel={140}
      />
      {children}
    </SharedServiceContext.Provider>
  );
};

export default SharedServiceProvider;
