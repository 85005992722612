import { useMemo } from 'react';
import ContainerFlex from '~/components/containerFlex';
import * as St from '../../styles';
import Input, { MaskTypes } from '~/components/input';
import Select from '~/components/select';
import {
  BASE_RESULT_FACES,
  BIOMETRICS_ORIGIN,
  RESULT_LIVENESS,
} from '../../models';
import Button from '~/components/button';
import { useFilterBiometrics } from '../../context/FilterBiometricContext';

interface IFilterBiometrics {
  onClick?: (any: any) => void;
}

const FilterBiometrics: React.FC<IFilterBiometrics> = ({ onClick }) => {
  const { form, setForm } = useFilterBiometrics();

  console.log('Form - ', form);
  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleClickForm = (el): void => {
    onClick?.(el);
  };

  const isButtonDisabled = useMemo(() => {
    return !(
      (form.cpf?.masked?.length === 14 && form.origem) ||
      (form.dataBiometriaInicial?.length === 10 &&
        form.dataBiometriaFinal?.length === 10)
    );
  }, [form]);

  return (
    <St.BoxCard>
      <ContainerFlex row space>
        <ContainerFlex marginRight={16} space gap="20">
          <Input
            label="CPF"
            name="cpf"
            maxLength={14}
            placeholder="000.000.000-00"
            value={form.cpf?.masked}
            mask={MaskTypes.CPF}
            onChange={handleChange}
          />
          <Select
            label="Origem"
            options={BIOMETRICS_ORIGIN}
            name="origem"
            value={form.origem}
            onChange={(selectedOption) => {
              setForm((prev) => ({ ...prev, origem: selectedOption }));
            }}
          />
        </ContainerFlex>
        <ContainerFlex marginRight={16} space gap="20">
          <Input
            label="De"
            placeholder="DD/MM/YYYY"
            name="dataBiometriaInicial"
            maxLength={10}
            value={form.dataBiometriaInicial}
            onChange={(e) => {
              setForm({
                ...form,
                dataBiometriaInicial: e.target.value.masked,
              });
            }}
            mask={MaskTypes.DATE}
          />
          <Select
            label="Resultado liveness"
            options={RESULT_LIVENESS}
            name="resultLiveness"
            value={form.resultLiveness}
            onChange={(selectedOption) => {
              setForm((prev) => ({
                ...prev,
                resultLiveness: selectedOption,
              }));
            }}
          />
        </ContainerFlex>
        <ContainerFlex marginRight={16} space gap="20">
          <Input
            label="Até"
            placeholder="DD/MM/YYYY"
            maxLength={10}
            value={form.dataBiometriaFinal}
            onChange={(e) => {
              setForm({
                ...form,
                dataBiometriaFinal: e.target.value.masked,
              });
            }}
            mask={MaskTypes.DATE}
          />
          <Select
            label="Resultado base faces"
            options={BASE_RESULT_FACES}
            name="resultLiveness"
            value={form.baseResultFace}
            onChange={(selectedOption) => {
              setForm((prev) => ({
                ...prev,
                baseResultFace: selectedOption,
              }));
            }}
          />
        </ContainerFlex>
        <ContainerFlex flex="0.2" marginTop={24} maxWidth={14}>
          <Button
            onClick={() => handleClickForm(form)}
            disabled={isButtonDisabled}
          >
            <span>Consultar</span>
          </Button>
        </ContainerFlex>
      </ContainerFlex>
    </St.BoxCard>
  );
};

export default FilterBiometrics;
